import type { Issue, PullRequest } from '@octokit/graphql-schema';
import type { QueryState } from 'functions/src/datasources/github-gql';
import { DateTime } from 'luxon';
import type React from 'react';
import type { ServiceReferences } from 'src/redux/modules/devProcess.reducer';

export interface Discipline {
  ref: string;
  name: string;
  emoji?: string;
}

export type DropdownBaseValue = string | number | symbol | null | boolean;

export interface DropdownBase<
  TValue extends DropdownBaseValue = string,
  TLabel extends string | React.ReactElement = string | React.ReactElement
> {
  value?: TValue;
  values?: Array<TValue>;
  label: TLabel;
  active?: boolean;
  avatar?: string;
  image?: string;
  imageSrcSet?: string;
  emoji?: string;
  key?: string;
}

// Todo: rename, we need to define actual stages
export enum Stages {
  // Pre-dev
  Inbox = 'Inbox', // New Tag: Triage: New?
  Icebox = 'Icebox', // @todo issues with no priority
  Backlog = 'Backlog',
  InDiscovery = 'InDiscovery',
  UpNext = 'UpNext',
  Priority = 'Priority',
  // Dev stages
  Blocked = 'Blocked',
  WorkInProgress = 'WorkInProgress',
  InCodeReview = 'InCodeReview',
  isBlockedInQA = 'isBlockedInQA',
  isRejectedByQA = 'isRejectedByQA',
  ReadyForQA = 'ReadyForQA',
  InQA = 'InQA',
  isAcceptedByQA = 'isAcceptedByQA',
  ReadyForMerge = 'ReadyForMerge',
  ReadyForRelease = 'ReadyForRelease',
  Released = 'Released',
  Graveyard = 'Graveyard',
  // Post-dev
  PublicAnnouncement = 'PublicAnnouncement',
  NO_IDEA = 'NO_IDEA', // Log these and fix them
  // Other = 'OTHER UNDEFINED ATM',
}

export type IntegrationKeys = 'github';

export type SourceType = IntegrationKeys | 'slack' | 'preview' | 'productboard' | 'blog' | 'link';

export interface GitHubItemStatus {
  isImportant: boolean;
  isMaxPriority: boolean;
  isStale: boolean;
  estimate?: number | null;
  isReleased: boolean;
  importance: number;
  importanceLabel?: string | null;
  severity: number | null;
  isLongRunning: boolean;
  daysOpen: number;
  daysWithoutActivity: number;
  messages: Array<any>;
  isBlocked: boolean;
  isEpic: boolean;
  isDone: boolean;
  triageLabel?: string | null;
  isNotable?: boolean;
  hasCustomerSpectators?: boolean;
  stage: Stages;
  overrideHasRemainingWork?: boolean;
  isQAInvolved?: boolean;
}

export interface StoryIssueRelationship {
  id?: string | number;
  relationRef: string;
  type?: 'close' | 'duplicate' | 'parent';
  source: SourceType;
  status?: GitHubItemStatus;
}

export interface GitHubItem {
  id: number;
  source: SourceType;
  /** If you had to tell someone, "you'll find it through..." */
  bucket_path: string;
  relationRef: string;
  belongs_to_project_refs: Array<string>;
  discipline_refs: Array<string>;
  disciplines?: Array<Discipline>;
  blocking_discipline_refs: Array<string>;
  blocking_disciplines?: Array<Discipline>;
  team_refs?: Array<string>;
  teams?: Array<Team>;
  area_refs: Array<string>;
  areas?: Array<Area>;
  title: string;
  classifications: Array<'bug' | 'chore' | 'feature' | 'epic' | 'service request' | 'spike'>;
  type: 'pull_request' | 'issue' | 'chStory' | 'blogpost';
  started_at?: DateTime | null;
  updated_at?: DateTime | null;
  completed_at?: DateTime | null;
  closed_at?: DateTime | null;
  assignee_refs: Array<string | number>;
  reviewers_refs: Array<string | number>;
  owners_refs: Array<string | number>;
  // ^^ refs used for lookups on client-side to generate...
  assignees?: Array<Person>;
  reviewers?: Array<Person>;
  owners?: Array<Person>;
  labels: Array<{ name?: string; color?: string }>;
  messages: Array<any>;
  comments: Array<{
    text: string;
    author: string;
    avatar?: string;
    link?: string;
    created_at?: string;
  }>;
  stage: Stages;
  relationships: Array<StoryIssueRelationship>;
  links: Array<{
    url: string;
    source: SourceType;
    title?: string;
  }>;
  status: GitHubItemStatus;
  children?: Array<GitHubItem>;
  parentRefs?: Array<string>;
  products: Array<string>;

  /** Concatenation of text properties for fulltext search. */
  searchBlob: string;
}

export type StoryIssueClassification = GitHubItem['classifications'][number];

// API RESPONSES

export interface ApiGetGithubItems extends ServiceReferences<Issue | PullRequest> {
  queryState: QueryState;
  releases: Record<string, string>;
}

export interface TimelyChangelog {
  id: string | number;
  headline: string;
  date: string;
  summary: string;
  category: 'Product Updates' | 'Changelog';
  url: string | null;
  image: string | null;
  image_alt: string | null;
  important_update: boolean;
}

export interface ApiGetTimelyChangelog {
  posts: Array<TimelyChangelog>;
}

export interface ApiGetTimelyChangelog {
  posts: Array<TimelyChangelog>;
}

export interface GithubRelease {
  subscriptionRef: string;
  name?: string | null;
  description?: string | null;
  date?: string | null;
  version: string;
}

export interface Leave {
  start: string;
  end: string;
  resumesWork: string;
  activeLeave: boolean;
  absentDays: number;
  startsInDays: number;
}

export interface Person {
  name: string;
  email: string;
  title: string | null;
  department: string;
  location: string;
  avatar?: string;
  tz: string | null;
  archived?: boolean;
  belongs_to_team_refs: Array<string>;
  teams?: Array<Discipline>;
  meta: {
    active: boolean;
    isSick: boolean;
    inDewoMode: boolean;
    activeLeave: boolean;
    hasPlannedLeave: boolean;
  };
  imStatus: {
    isSet: boolean;
    text: string | undefined;
    emoji: string | undefined;
  };
  leave: Array<Leave>;
  externalReference: {
    freshteamId?: number;
    freshteamManagerId?: number;
    github?: string;
    slackId?: string;
    slackTeamId?: string;
  };
  searchBlob: string;
}

export interface PersonInHierarchy extends Person {
  directReports: Array<PersonInHierarchy>;
}

export interface KeyMetricTrend {
  value: number;
  date: string;
  label?: string;
}

export interface KeyMetric {
  formatted_value: string;
  category: string;
  label: string;
  trending: 'up' | 'down' | 'neutral';
  sentiment: 'positive' | 'negative' | 'neutral';
  differenceInPercent: string;
  trends: Array<KeyMetricTrend>;
  trendSetting: {
    min?: number;
    max?: number;
  };
}

export interface ApiGetKeyMetrics {
  data: Array<KeyMetric>;
}
export interface ApiGetPeople {
  data: Array<Person>;
}
export interface ApiGetTeams {
  data: Array<Discipline>;
}

export interface ApiChartMogulKeyMetric {
  date: string;
  'customer-churn-rate': number;
  'mrr-churn-rate': number;
  ltv: number;
  customers: number;
  asp: number;
  arpa: number;
  arr: number;
  mrr: number;
}

export interface ProductVision {
  title: string;
  tagline?: string;
  description: string;
  body: string;
  highlights: Array<string>;
  nextSteps: Array<string>;
  references: Array<any>;
}

export type RoadmapPlan = ProductVision & {
  priority: number;
  evergreen: boolean;
  meta: Array<{ label: string; value: string; span?: number }>;
  githubRefs: Array<string>;
  enrichedGithubRefs: Array<GitHubItem>;
};

export interface Team {
  ref: string;
  name: string;
  emoji?: string;
  image?: string;
  imageSrcSet?: string;
}

export interface Area {
  ref: string;
  name: string;
}
