import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { GitHubItem, Stages, SourceType } from 'src/types';
import {
  setFilter,
  setOpenTicketRef,
  fetchedGithubRepo,
  setFetchTimestamps,
  setStages,
  fetchGithubDataAsNeeded,
} from './devProcess.actions';
import uniq from 'lodash/uniq';
import type { Issue, PullRequest } from '@octokit/graphql-schema';
import { isEqual } from 'lodash';

/**
 * Increment this number when changes to `functions/src/datasources/github-gql.ts` or the subscribed
 * repositories require the local cache to be purged.
 */
export const GITHUB_STATE_VERSION = 3;

export interface TicketRef {
  relationRef: string;
  source: SourceType;
}

export interface Filter {
  areas: Array<string> | null;
  disciplines: Array<string> | null;
  projects: Array<string>;
  searchQuery: string | undefined;
  sources: Array<string>;
  sprints: Array<string>;
  teams: Array<string> | null;
  ticketTypes: Array<string> | null;
  users: Array<string>;
}

export interface Options {
  excludeEmptyLanes: boolean;
  limitTicketsPerStage: boolean;
}

export interface ServiceReferences<T = GitHubItem> {
  map: { [key: string]: T };
  refs: Array<string>;
}

interface GithubState extends ServiceReferences<Issue | PullRequest> {
  hasCheckedCache: boolean;
  labels: Array<string>;
  mostRecentItemUpdate?: string;
  releases: Record<string, string>;
  version?: number;
}

export type DevProcessState = {
  filter: Filter;
  options: Options;
  openTicketRef: null | TicketRef;
  stages: Array<Stages> | null;
  github: GithubState;
  // Keep outside of github object to avoid persist calls when we have refetched with nothing new
  githubFetchHasErrors?: boolean;
};

export const defaultFilter: Filter = {
  areas: null,
  projects: [],
  ticketTypes: null,
  disciplines: null,
  users: [],
  searchQuery: '',
  sources: [],
  sprints: [],
  teams: null,
};

export const initialState: DevProcessState = {
  filter: { ...defaultFilter },
  options: {
    excludeEmptyLanes: true,
    limitTicketsPerStage: true,
  },
  openTicketRef: null,
  stages: null,
  github: {
    hasCheckedCache: false,
    labels: [],
    map: {},
    refs: [],
    releases: {},
  },
};

const devProcessSlice = createSlice({
  name: 'devProcess',
  initialState,
  reducers: {
    setOptions(state, action: PayloadAction<Partial<Options>>) {
      state.options = {
        ...state.options,
        ...action.payload,
      };
    },
    purgeGitHubData(state) {
      state.github = initialState.github;
    },
    setLabels(state, action: PayloadAction<Array<string>>) {
      if (action.payload.length > 0) {
        state.github.labels = action.payload;
      }
    },
    resetFilters(state) {
      state.filter = initialState.filter;
      state.stages = null;
    },
    setHasCheckedCache(state, action: PayloadAction<boolean>) {
      state.github.hasCheckedCache = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchedGithubRepo, (state, action) => {
      if (action.payload.refs.length > 0) {
        state.github.refs = uniq([...state.github.refs, ...action.payload.refs]);
        state.github.map = { ...state.github.map, ...action.payload.map };
      }
      if (!isEqual(state.github.releases, action.payload.releases)) {
        state.github.releases = { ...state.github.releases, ...action.payload.releases };
      }
    });

    builder.addCase(fetchGithubDataAsNeeded.fulfilled, state => {
      state.githubFetchHasErrors = false;
    });

    builder.addCase(fetchGithubDataAsNeeded.rejected, state => {
      state.githubFetchHasErrors = true;
    });

    builder.addCase(setFilter, (state, action) => {
      const patch = { ...action.payload };
      if ('searchQuery' in patch) {
        patch.searchQuery = patch.searchQuery?.toLowerCase();
      }

      Object.assign(state.filter, patch);
    });

    builder.addCase(setOpenTicketRef, (state, action) => {
      state.openTicketRef = action.payload;
    });

    builder.addCase(setFetchTimestamps, (state, action) => {
      if (state.github.mostRecentItemUpdate !== action.payload.mostRecentItemUpdate) {
        state.github.mostRecentItemUpdate = action.payload.mostRecentItemUpdate;
      }
    });

    builder.addCase(setStages, (state, action) => {
      state.stages = action.payload;
    });
  },
});

export const { resetFilters, setOptions, purgeGitHubData, setLabels } = devProcessSlice.actions;

export default devProcessSlice.reducer;
