import { useCallback } from 'react';
import type { DropdownBaseValue } from 'src/types';

export const notNullOrUndefined = <T>(item: T | null | undefined): item is T => item != null;

export const getTrendlineParams = <TItem>(
  data: Array<TItem>,
  iteratee: (item: TItem, index: number) => [x: number, y: number]
): [a: number, b: number] => {
  const n = data.length;
  if (!n) {
    return [0, 0];
  }

  let sumX = 0;
  let sumXX = 0;
  let sumXY = 0;
  let sumY = 0;
  for (let i = 0; i < n; ++i) {
    const [x, y] = iteratee(data[i], i);
    if (isNaN(y)) continue;

    sumX += x;
    sumXX += x ** 2;
    sumXY += x * y;
    sumY += y;
  }

  const b = (sumXY - (sumX * sumY) / n) / (sumXX - sumX ** 2 / n);
  const a = sumY / n - b * (sumX / n);

  return [a, b];
};

export const isMacOs = () => {
  return /Mac/i.test(navigator.userAgent);
};

let listFormat: Intl.ListFormat;

export const joinStrings = ((): ((s?: Array<string>) => string) => {
  if ('ListFormat' in Intl) {
    listFormat = new Intl.ListFormat('en', {
      style: 'long',
      type: 'conjunction',
    });
    return (s = []) => listFormat.format(s);
  } else {
    // Backfill for Safari
    return (s = []) => s.join(', ');
  }
})();

export const isMultiSelectOptionActive = <T extends DropdownBaseValue>(
  values: T | Array<T>,
  selectedValues: Array<T> | null
): boolean => {
  const hasArrayValue = Array.isArray(values);
  return (
    !selectedValues ||
    (hasArrayValue ? values.some(v => selectedValues.includes(v)) : selectedValues.includes(values))
  );
};

export const useOnOff = (setter: React.Dispatch<React.SetStateAction<boolean>>) => {
  const on = useCallback(() => setter(true), [setter]);
  const off = useCallback(() => setter(false), [setter]);

  return [on, off] as const;
};
