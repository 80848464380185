export function perfWrapStatement<T>(
  name: string,
  message: string | ((result: T) => string),
  fn: () => T
): T {
  performance.mark(`${name}-start`);
  const result = fn();

  performance.mark(`${name}-end`);
  performance.measure(`${name}`, `${name}-start`, `${name}-end`);
  const perfEntry = performance.getEntriesByName(`${name}`, `measure`)[0];
  performance.clearMarks(`${name}-start`);
  performance.clearMarks(`${name}-end`);
  performance.clearMeasures(`${name}`);
  console.info(
    `%s in %dms`,
    typeof message === 'string' ? message : message(result),
    perfEntry?.duration
  );

  return result;
}
