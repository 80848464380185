import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from 'src/api';
import { fetchPeople } from './people.actions';
import {
  fetchGithubDataAsNeeded,
  fetchGitHubLabels,
  GITHUB_SUBSCRIPTION_CHUNK_SIZE,
} from './devProcess.actions';
import { getWorkspaceGitHubSubscriptions, getWorkspaceMeta } from './workspace.selectors';
import type { RootState } from '../rootReducer';
import { createAuthenticatedApiThunk } from '../utils';

export const fetchTeams = createAuthenticatedApiThunk(
  'workspace/fetchTeams',
  async ({ apiOptionsWithAuthHeader }) => {
    return await api.getTeams(apiOptionsWithAuthHeader);
  }
);

export const fetchEnvironments = createAsyncThunk('workspace/fetchEnvironments', async () => {
  return await api.getEnvironments();
});

export const incrementLoadingProgress = createAction('workspace/incrementLoadingProgress');
export const setLoadingMaxProgress = createAction<number>('workspace/setLoadingMaxProgress');
export const incrementLoadingMaxProgress = createAction('workspace/incrementLoadingMaxProgress');

export const smartlyInitializeAllDataSources = createAsyncThunk(
  'workspace/fetchAllDataSources',
  (params, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const workspaceMeta = getWorkspaceMeta(state);
    const gitHubSubscriptions = getWorkspaceGitHubSubscriptions(state);

    // Avoid re-fetching data we already know about
    // Todo: The over-all approach here is super naive and basic,
    // obvs has a lot of potential for improvement.
    if (workspaceMeta.isInitialized) return;

    const gitHubRequestCount =
      Math.floor(gitHubSubscriptions.length / GITHUB_SUBSCRIPTION_CHUNK_SIZE) +
      (gitHubSubscriptions.length % GITHUB_SUBSCRIPTION_CHUNK_SIZE);
    thunkAPI.dispatch(setLoadingMaxProgress(3 + gitHubRequestCount));

    const people = thunkAPI
      .dispatch(fetchPeople())
      .then(() => thunkAPI.dispatch(incrementLoadingProgress()));

    const teams = thunkAPI
      .dispatch(fetchTeams())
      .then(() => thunkAPI.dispatch(incrementLoadingProgress()));

    // GitHub will increment progress individually for each repo
    const githubSource = thunkAPI.dispatch(fetchGithubDataAsNeeded());

    const envs = thunkAPI
      .dispatch(fetchEnvironments())
      .then(() => thunkAPI.dispatch(incrementLoadingProgress()));

    const gitHubLabels = thunkAPI.dispatch(fetchGitHubLabels());

    return Promise.all([people, teams, githubSource, envs, gitHubLabels]);
  }
);
