import React from 'react';
import { createSelector } from '@reduxjs/toolkit';
import { getFilter } from './filter.selector';
import type { RootState } from '../rootReducer';
import { naturalCompare } from 'src/utils/sort';
import type { Area, Discipline, DropdownBase } from 'src/types';
import { labelToRefFactory } from 'src/factories/github';
import { EMPTY_ARRAY } from 'src/utils/constants';
import { Filter } from './devProcess.reducer';

const readWorkspaceMeta = (state: RootState) => state.workspace.meta;
const readWorkspaceEnvironments = (state: RootState) => state.workspace.environments;
// const readWorkspaceLabels = (state: RootState) => state.workspace.labels;
const readWorkspaceIntegrations = (state: RootState) => state.workspace.integrations;
const readworkspaceProjects = (state: RootState) => state.workspace.projects;
const readWorkspaceTeams = (state: RootState) => state.workspace.teams;

export const getWorkspaceMeta = createSelector(readWorkspaceMeta, meta => {
  return meta;
});

export const getWorkspaceEnvironments = createSelector(readWorkspaceEnvironments, environments => {
  return environments.refs.map(ref => environments.map[ref]);
});

export const getWorkspaceProjects = createSelector(readworkspaceProjects, projects => {
  return projects.refs.map(ref => projects.map[ref]);
});

export const getWorkspaceIntegrationsMap = createSelector(
  readWorkspaceIntegrations,
  integrations => integrations.map
);

export const getWorkspaceGitHubSubscriptions = createSelector(
  getWorkspaceIntegrationsMap,
  integrations => integrations.github.subscriptions.filter(sub => sub.subscribed)
);

const readGitHubLabels = (state: RootState) => state.devProcess.github.labels ?? EMPTY_ARRAY;

const getDisciplinesFilter = createSelector([getFilter], filter => filter.disciplines);

export const getWorkspaceDisciplineLabels = createSelector([readGitHubLabels], labels =>
  labels.filter(label => label.startsWith('Discipline: '))
);

export const getWorkspaceDisciplinesAsOptions = createSelector(
  [getWorkspaceDisciplineLabels, getDisciplinesFilter],
  (labels, disciplines) => {
    const options = labels.map<DropdownBase<string, string>>(label => {
      const name = label.replace('Discipline: ', '');
      const ref = labelToRefFactory(name);
      return {
        label: name,
        value: ref,
        active: !!disciplines?.includes(ref),
      };
    });

    return options.sort((a, b) => naturalCompare(a.label, b.label));
  }
);

export const getWorkspaceDisciplinesMap = createSelector(
  [getWorkspaceDisciplinesAsOptions],
  options => {
    return options.reduce<Record<string, Discipline>>((map, { label: name, value: ref }) => {
      map[ref!] = {
        ref: ref!,
        name,
      };
      return map;
    }, {});
  }
);

export const getWorkspaceTeamsMap = createSelector(readWorkspaceTeams, teams => {
  return teams.map;
});

export const getTeamsAsOptions = createSelector(readWorkspaceTeams, teams => {
  const optionIconsMap: Record<string, React.ReactNode> = {};
  const optionsMap = Object.entries(teams.map).reduce<Record<string, string>>(
    (optionsMap, [ref, team]) => {
      // Temp workaround to introduce a legacy label in the middle
      if (ref === 'aha') {
        optionsMap['legacy'] = 'legacy';
      }

      optionsMap[team.name] = ref;

      if (team.emoji) {
        optionIconsMap[team.name] = (
          <span className="w-4 text-center mr-2" role="img">
            {team.emoji}
          </span>
        );
      } else if (team.image) {
        optionIconsMap[team.name] = (
          <img
            src={team.image}
            srcSet={team.imageSrcSet}
            className="w-4 h-4 mr-2"
            alt={team.name}
          />
        );
      }

      return optionsMap;
    },
    { Undefined: '?' }
  );

  return [optionsMap, optionIconsMap] as const;
});

export const getProductsAsOptions = createSelector(
  [getWorkspaceProjects, getFilter],
  (projects, filter) => {
    const options = projects.map<DropdownBase<string, string>>(project => ({
      label: project.name,
      value: project.ref,
      active: filter.projects.includes(project.ref),
      image: project?.image,
      imageSrcSet: project?.imageSrcSet,
    }));
    return options.sort((a, b) => naturalCompare(a.label, b.label));
  }
);

export const getSourcesAsOptions = createSelector(
  [getFilter],
  filter => {
    return [
      {
        value: 'issue',
        label: 'Issues only',
        active: filter.sources.includes('issue'),
      },
      {
        value: 'pull_request',
        label: 'PRs only',
        active: filter.sources.includes('pull_request'),
      },
    ];
  },
  {
    memoizeOptions: {
      equalityCheck: (a: Filter, b: Filter) => a.sources === b.sources,
    },
  }
);

export const getSprintsAsOptions = createSelector(
  [getFilter],
  filter => {
    return [
      {
        value: 'in',
        label: 'In sprint',
        active: filter.sprints.includes('in'),
      },
      {
        value: 'out',
        label: 'Outside sprint',
        active: filter.sprints.includes('out'),
      },
    ];
  },
  {
    memoizeOptions: {
      equalityCheck: (a: Filter, b: Filter) => a.sprints === b.sprints,
    },
  }
);

export const getHasInitialized = createSelector([getWorkspaceMeta], meta => meta.isInitialized);

const getAreasFilter = createSelector([getFilter], filter => filter.areas);

export const getWorkspaceAreaLabels = createSelector([readGitHubLabels], labels =>
  labels.filter(label => label.startsWith('Area: '))
);

export const getWorkspaceAreasAsOptions = createSelector(
  [getWorkspaceAreaLabels, getAreasFilter],
  (labels, areas) => {
    const options = labels.map<DropdownBase<string, string>>(label => {
      const name = label.replace('Area: ', '');
      const ref = labelToRefFactory(name);
      return {
        label: name,
        value: ref,
        active: !!areas?.includes(ref),
      };
    });

    return options.sort((a, b) => naturalCompare(a.label, b.label));
  }
);

export const getWorkspaceAreasMap = createSelector([getWorkspaceAreasAsOptions], options => {
  return options.reduce<Record<string, Area>>((map, { label: name, value: ref }) => {
    map[ref!] = {
      ref: ref!,
      name,
    };
    return map;
  }, {});
});
