//import 'src/utils/why-did-you-render';

import React from 'react';
import { Provider } from 'react-redux';
import store from 'src/redux/store';

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  // const store = createStore();

  // Note that React.StrictMode breaks rendering of the Board component in development, becaus
  // StrictMode renders components twice to detect side effects, and Board relies on such a side
  // effect. This should probably be fixed if possible...
  return (
    //<React.StrictMode>
    <Provider store={store}>{element}</Provider>
    //</React.StrictMode>
  );
};
