import { createSlice } from '@reduxjs/toolkit';
import { fetchKeyMetrics } from './metrics.actions';
import type { KeyMetric } from 'src/types';

type MetricsState = {
  keyMetrics: Array<KeyMetric>;
};

let initialState: MetricsState = {
  keyMetrics: [],
};

const metricsSlice = createSlice({
  name: 'metrics',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchKeyMetrics.fulfilled, (state, action) => {
      state.keyMetrics = action.payload;
    });
  },
});

export default metricsSlice.reducer;
