import environmentsMock from '../../__mocks__/api/environments.json';
import type {
  ApiGetGithubItems,
  ApiGetTimelyChangelog,
  ApiGetPeople,
  ApiGetTeams,
  ApiGetKeyMetrics,
  KeyMetric,
  ProductVision,
  RoadmapPlan,
} from 'src/types';
import type { IntegrationSubscription } from 'types/common';
import type { TopApp } from 'functions/src/airtable_top_apps';
import type { MetricRecord, QueryState } from 'functions/src/datasources/github-gql';
import { merge } from 'lodash';

function getBaseUrl() {
  const isLocalDev = typeof window !== 'undefined' && window.location.href.includes('localhost');
  return isLocalDev ? 'http://localhost:9000' : '';
}

export async function getGitHubItems(
  {
    subscriptions,
    queryState,
    updatedSince,
  }: {
    subscriptions: Array<IntegrationSubscription>;
    queryState?: QueryState;
    updatedSince?: string;
  },
  options: RequestInit = {}
) {
  const url = `${getBaseUrl()}/.netlify/functions/github_data_for_repo`;
  const request = await post<ApiGetGithubItems>(
    url,
    { subscriptions, queryState, updatedSince },
    options
  );
  return request;
}

export async function getTimelyChangelog(options: RequestInit = {}) {
  const url = 'https://dewo.netlify.app/.netlify/functions/timely_product_updates';
  return get<ApiGetTimelyChangelog>(url, options).then(data => data.posts);
}

export async function getPeople(options: RequestInit = {}) {
  const url = `${getBaseUrl()}/.netlify/functions/people`;
  return get<ApiGetPeople>(url, options).then(res => res.data);
}

export async function getTeams(options: RequestInit = {}) {
  const url = `${getBaseUrl()}/.netlify/functions/teams`;
  return get<ApiGetTeams>(url, options).then(res => res.data);
}

export async function getEnvironments(options: RequestInit = {}) {
  return Promise.resolve(environmentsMock);
}

export async function getKeyMetrics(options: RequestInit = {}) {
  const url = `${getBaseUrl()}/.netlify/functions/key_metrics`;
  return get<ApiGetKeyMetrics>(url, options).then(res => res.data);
}

export async function fetchFigmaChangelog(options: RequestInit = {}) {
  const url = `${getBaseUrl()}/.netlify/functions/figma_feed`;
  // todo: fix types if we keep this
  return get<any>(url, options).then(res => res.data);
}

export async function fetchAirtableTools(options: RequestInit = {}) {
  const url = `${getBaseUrl()}/.netlify/functions/airtable_tools`;
  // todo: fix types if we keep this
  return get<any>(url, options).then(res => res.data);
}

export async function fetchAirtableTopApps(options: RequestInit = {}) {
  const url = `${getBaseUrl()}/.netlify/functions/airtable_top_apps`;
  // todo: fix types if we keep this
  return get<{ data: Array<TopApp> }>(url, options).then(res => res.data);
}
export async function fetchAmplitudeAIDraftTrends(options: RequestInit = {}) {
  const url = `${getBaseUrl()}/.netlify/functions/amplitude_ai_draft_trends`;
  return get<{ data: Array<KeyMetric> }>(url, options).then(res => res.data);
}

export async function getRoadmapData(options: RequestInit = {}) {
  const url = `${getBaseUrl()}/.netlify/functions/roadmap_data`;
  return get<{ plans: Array<RoadmapPlan>; visions: Array<ProductVision> }>(url, options);
}

export async function getGitHubMetrics(options: RequestInit = {}) {
  const url = `${getBaseUrl()}/.netlify/functions/github_metrics`;
  return get<Array<MetricRecord>>(url, options);
}

export async function getGitHubLabels(options: RequestInit = {}) {
  const url = `${getBaseUrl()}/.netlify/functions/github_labels`;
  return get<Array<string>>(url, options);
}

export async function post<T>(url: string, data: Object, options: RequestInit = {}): Promise<T> {
  const finalOptions = merge(
    {
      method: 'POST',
      mode: 'cors', // no-cors, *cors, same-origin
      credentials: 'same-origin', // include, *same-origin, omit
      body: JSON.stringify(data || {}),
      headers: {
        'Content-Type': 'application/json',
      },
    },
    options
  );
  return makeRequest(url, finalOptions);
}

export async function get<T>(url: string, options: RequestInit = {}): Promise<T> {
  const finalOptions = merge(
    {
      method: 'get',
      mode: 'cors', // no-cors, *cors, same-origin
      credentials: 'same-origin', // include, *same-origin, omit
    },
    options
  );
  return makeRequest(url, finalOptions);
}

export async function makeRequest<T>(url: string, options: RequestInit = {}): Promise<T> {
  const response = await fetch(url, options);
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  const data = await (response.json() as Promise<T>);
  return data;
}
