import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { batch } from 'react-redux';
import * as api from 'src/api';
import { getWorkspaceGitHubSubscriptions } from './workspace.selectors';
import { getGithubMostRecentItemUpdate, getCanPollForGithubChanges } from './devProcess.selectors';
import { incrementLoadingMaxProgress, incrementLoadingProgress } from './workspace.actions';
import type { RootState } from '../rootReducer';
import {
  Filter,
  GITHUB_STATE_VERSION,
  ServiceReferences,
  setLabels,
  TicketRef,
} from './devProcess.reducer';
import { chunk, maxBy } from 'lodash';
import type { ApiGetGithubItems, GitHubItem, Stages } from 'src/types';
import type { Issue, PullRequest } from '@octokit/graphql-schema';
import { getGitHub } from './devProcess.selectors';
import { createAuthenticatedApiThunk } from '../utils';
import * as idb from 'idb';

export const setFilter = createAction('devProcess/setFilter', (patch: Partial<Filter>) => {
  return {
    payload: patch,
  };
});

export const setOpenTicketRef = createAction(
  'devProcess/setOpenTicketRef',
  (payload: TicketRef | null) => ({ payload })
);

export const fetchedGithubRepo = createAction(
  'devProcess/fetchedGithubRepo',
  (payload: ServiceReferences<Issue | PullRequest> & { releases: Record<string, string> }) => {
    return {
      payload,
    };
  }
);

export const setFetchTimestamps = createAction(
  'devProcess/setFetchTimestamps',
  (payload: { mostRecentItemUpdate: string; lastFetchAt: string }) => {
    return {
      payload,
    };
  }
);

interface GitHubDb extends idb.DBSchema {
  items: {
    key: string;
    value: (Issue | PullRequest) & { __ref: string };
  };

  releases: {
    key: string;
    value: {
      repo: string;
      date: string;
    };
  };
}

const assertDb = async (): Promise<idb.IDBPDatabase<GitHubDb> | void> => {
  // Purge old `redux-persist` cached stuff
  for (const key of Object.keys(localStorage).filter(key => key.startsWith('persist:'))) {
    localStorage.removeItem(key);
  }

  if (!('indexedDB' in window)) {
    console.warn('IndexedDB not supported - GitHub data will not be cached.');
    return;
  }

  return idb.openDB('github', GITHUB_STATE_VERSION, {
    upgrade(db) {
      if (!db.objectStoreNames.contains('items')) {
        db.createObjectStore('items', { keyPath: '__ref' });
      }
      if (!db.objectStoreNames.contains('releases')) {
        db.createObjectStore('releases', { keyPath: 'repo' });
      }
    },
  });
};

const createEmptyGitHubResult = (): ApiGetGithubItems => ({
  map: {},
  refs: [],
  releases: {},
  queryState: { hasMorePages: false, pagination: {} },
});

const loadCachedGitHubData = createAsyncThunk<void, void, { state: RootState }>(
  'devProcess/loadCachedGitHubData',
  async (_, { dispatch, getState }) => {
    const db = await assertDb();
    if (!db) return;

    const subscribedRepos = new Set(
      getWorkspaceGitHubSubscriptions(getState()).map(r => r.ref.replace('Timely/', ''))
    );

    const deletionRequests: Array<Promise<unknown>> = [];

    const cachedResult = createEmptyGitHubResult();

    const items = await db.getAll('items');
    for (const { __ref, ...item } of items) {
      if (subscribedRepos.has(item.repository.name)) {
        cachedResult.map[__ref] = item;
        cachedResult.refs.push(__ref);
      } else {
        deletionRequests.push(db.delete('items', __ref));
      }
    }

    const releases = await db.getAll('releases');
    for (const { repo, date } of releases) {
      if (subscribedRepos.has(repo.replace('Timely/', ''))) {
        cachedResult.releases[repo] = date;
      } else {
        deletionRequests.push(db.delete('releases', repo));
      }
    }

    const fetchedAt = new Date().toISOString();

    dispatch(fetchedGithubRepo(cachedResult));
    await dispatch(saveMostRecentGithubItemUpdate({ fetchedAt }));
    await Promise.all(deletionRequests);
  }
);

const cacheGitHubData = createAsyncThunk(
  'devProcess/cacheGitHubData',
  async (params: ApiGetGithubItems) => {
    const db = await assertDb();
    if (!db) return;

    const itemsTransaction = db.transaction('items', 'readwrite');
    const items = itemsTransaction.objectStore('items');
    for (const [ref, item] of Object.entries(params.map)) {
      items.put({ ...item, __ref: ref });
    }

    const releasesTransaction = db.transaction('releases', 'readwrite');
    const releases = releasesTransaction.objectStore('releases');
    for (const [repo, date] of Object.entries(params.releases)) {
      releases.put({ repo, date });
    }

    await Promise.all([itemsTransaction.done, releasesTransaction.done]);
  }
);

export const GITHUB_SUBSCRIPTION_CHUNK_SIZE = 1;

const GITHUB_GQL_RATE_LIMIT = 5_000;

export const fetchGithubDataAsNeeded = createAuthenticatedApiThunk(
  'devProcess/fetchGithubDataAsNeeded',
  async ({ apiOptionsWithAuthHeader, dispatch, getState }) => {
    try {
      await dispatch(loadCachedGitHubData());
    } catch (e) {
      console.error(e);
    }

    const state = getState();

    const gitHubSubscriptions = getWorkspaceGitHubSubscriptions(state);

    performance.mark('github-start');

    const lastDataUpdatedInState = getGithubMostRecentItemUpdate(state);
    const fetchOnlyUpdated = getCanPollForGithubChanges(state);

    const subsToFetch = fetchOnlyUpdated
      ? [gitHubSubscriptions]
      : chunk(gitHubSubscriptions, GITHUB_SUBSCRIPTION_CHUNK_SIZE);

    let requestCount = 0;
    let remainingRateLimitPoints = GITHUB_GQL_RATE_LIMIT;
    const results: Array<ApiGetGithubItems> = [];
    const fetchList = subsToFetch.map(async subscriptionsChunk => {
      const chunkResults: Array<ApiGetGithubItems> = [];
      let response: ApiGetGithubItems | undefined;
      do {
        requestCount++;

        const currentResponse = await api.getGitHubItems(
          {
            subscriptions: subscriptionsChunk,
            queryState: response?.queryState,
            updatedSince: lastDataUpdatedInState,
          },
          apiOptionsWithAuthHeader
        );

        batch(() => {
          if (currentResponse.queryState.hasMorePages) {
            dispatch(incrementLoadingMaxProgress());
          }
          dispatch(incrementLoadingProgress());
          chunkResults.push(currentResponse);
        });

        remainingRateLimitPoints = Math.min(
          remainingRateLimitPoints,
          currentResponse.queryState.remainingRateLimitPoints ?? GITHUB_GQL_RATE_LIMIT
        );

        response = currentResponse;
      } while (response?.queryState.hasMorePages);

      const combinedChunkResults = chunkResults.reduce((final: ApiGetGithubItems, result) => {
        Object.assign(final.map, result.map);
        Object.assign(final.releases, result.releases);
        Array.prototype.push.apply(final.refs, result.refs);
        return final;
      }, createEmptyGitHubResult());

      dispatch(cacheGitHubData(combinedChunkResults));
      results.push(combinedChunkResults);
    });

    await Promise.all(fetchList);
    const fetchedAt = new Date().toISOString();

    const combinedResults = results.reduce((final: ApiGetGithubItems, result) => {
      Object.assign(final.map, result.map);
      Object.assign(final.releases, result.releases);
      final.refs.push(...result.refs);
      return final;
    }, createEmptyGitHubResult());
    dispatch(fetchedGithubRepo(combinedResults));

    // Only save fetch date if all promises succeeded
    await dispatch(saveMostRecentGithubItemUpdate({ fetchedAt }));

    performance.mark('github-end');
    performance.measure('github', 'github-start', 'github-end');
    const perfEntry = performance.getEntriesByName('github', 'measure')[0];
    performance.clearMarks('github-start');
    performance.clearMarks('github-end');
    performance.clearMeasures('github');
    console.info(
      'Loaded %d (%d refs) repos in %dms (%d requests, %d points remaining)',
      gitHubSubscriptions.length,
      combinedResults.refs.length,
      perfEntry?.duration,
      requestCount,
      remainingRateLimitPoints
    );
  }
);

const saveMostRecentGithubItemUpdate = createAsyncThunk(
  'devProcess/saveMostRecentGithubItemUpdate',
  async (
    args: {
      fetchedAt: string;
    },
    thunkAPI
  ) => {
    const state = thunkAPI.getState() as RootState;

    const gitHub = getGitHub(state);
    const mostRecentUpdatedItem = maxBy(
      gitHub.refs.map(ref => gitHub.map[ref]),
      (item: GitHubItem) => {
        if (!item.updated_at) {
          return -1;
        }
        return +item.updated_at;
      }
    );
    if (mostRecentUpdatedItem) {
      thunkAPI.dispatch(
        setFetchTimestamps({
          mostRecentItemUpdate: mostRecentUpdatedItem.updated_at!.toISO(),
          lastFetchAt: args.fetchedAt,
        })
      );
    }
  }
);

export const setStages = createAction<Array<Stages> | null>('devProcess/setStages');

export const fetchGitHubLabels = createAuthenticatedApiThunk(
  'devProcess/fetchGitHubLabels',
  async ({ apiOptionsWithAuthHeader, dispatch }) => {
    const start = performance.now();
    const labels = await api.getGitHubLabels(apiOptionsWithAuthHeader);
    dispatch(setLabels(labels));
    const end = performance.now() - start;
    console.info('Loaded %d labels in %dms', labels.length, end);
  }
);
