import { createSlice } from '@reduxjs/toolkit';
import { fetchPeople } from './people.actions';
import type { Person } from 'src/types';

type PeopleState = {
  filter: {
    searchQuery: string | undefined;
    includeInactive: boolean;
    layout: 'table' | 'cards';
    orderBy: 'anomalies' | 'alphanumeric';
  };
  entries: Array<Person>;
  hasInitialized: boolean;
};

let initialState: PeopleState = {
  filter: {
    searchQuery: '',
    includeInactive: false,
    layout: 'table',
    orderBy: 'anomalies',
  },
  entries: [],
  hasInitialized: false,
};

const peopleSlice = createSlice({
  name: 'people',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchPeople.fulfilled, (state, action) => {
      state.entries = action.payload;
      state.hasInitialized = true;
    });
  },
});

export default peopleSlice.reducer;
