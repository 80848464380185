import type { AnyAction, Middleware } from '@reduxjs/toolkit';
import type { RootState } from '../rootReducer';
import { login, logout } from './auth';

const channelName = 'auth';

/**
 * True if the browser supports the Broadcast Channel API, which is used by the auth middleware.
 */
export const isAuthMiddlewareSupported =
  typeof window !== 'undefined' && 'BroadcastChannel' in window;

/**
 * Middleware that keeps all tabs in sync when the user logs in/out of the dashboard.
 */
export const createAuthMiddleware = (): Middleware<{}, RootState> => {
  return store => {
    const channel = new BroadcastChannel(channelName);

    channel.addEventListener('message', (event: MessageEvent<AnyAction>) => {
      const action: typeof event.data = { ...event.data, meta: { fromAuthMiddleware: true } };
      store.dispatch(action);
    });

    return next => (action: AnyAction) => {
      if (action.type === login.type || action.type === logout.type) {
        if (!action.meta?.fromAuthMiddleware) {
          channel.postMessage(action);
        }
      }
      next(action);
    };
  };
};
