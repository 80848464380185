import { createSelector } from '@reduxjs/toolkit';
import { allStages, stageIndices, stageMap } from './devProcess.constants';
import { devTicketsToList, findEntityRefs } from './devProcess.utils';
import { getPeople, getPeopleMap, getFilteredPeople } from './people.selector';
import { GitHubItem, Stages } from 'src/types';
import {
  getWorkspaceDisciplinesMap,
  getWorkspaceEnvironments,
  getWorkspaceGitHubSubscriptions,
  getWorkspaceTeamsMap,
  getWorkspaceAreasMap,
} from './workspace.selectors';
import { getFilter } from './filter.selector';
import { isAfter, sub } from 'date-fns';
import { notNullOrUndefined } from 'src/utils';
import type { RootState } from '../rootReducer';
import {
  labelToRefFactory,
  getOrgAndRepoFromGithubUrl,
  gitHubItemFactory,
} from 'src/factories/github';
import { keyBy, uniq, uniqBy } from 'lodash';
import { perfWrapStatement } from 'src/utils/performance';
import type { IntegrationSubscription } from 'types/common';
import { naturalCompare } from 'src/utils/sort';
import { getCurrentUserEmail } from './auth.selectors';
import { DateTime } from 'luxon';

const readOpenTicketRef = (state: RootState) => state.devProcess.openTicketRef;
const readGithub = (state: RootState) => state.devProcess.github;
const readGitHubRefs = (state: RootState) => state.devProcess.github.refs;
const readGitHubMap = (state: RootState) => state.devProcess.github.map;
const readGitHubReleases = (state: RootState) => state.devProcess.github.releases;

const getSubscriptionsByLowercaseRepoRef = createSelector(
  [getWorkspaceGitHubSubscriptions],
  subscriptions => keyBy(subscriptions, sub => sub.ref.toLowerCase())
);

const EMPTY_SUBSCRIPTION: IntegrationSubscription = {
  ref: 'none',
  subscribed: false,
  belongs_to_project_refs: [],
  discipline_refs: [],
};

// Enrich the map and cache item by item
const getEnrichedGithubItems = createSelector(
  [readGitHubRefs, readGitHubMap, readGitHubReleases, getSubscriptionsByLowercaseRepoRef],
  (refs, items, releases, subscriptionByLowercaseRepoRef) => {
    return refs.reduce<Record<string, GitHubItem>>((map, ref) => {
      const item = items[ref];
      const repoRef = getOrgAndRepoFromGithubUrl(item.url).toLowerCase();
      let subscription = subscriptionByLowercaseRepoRef[repoRef];
      if (!subscription) {
        console.warn(
          'Processing item with no subscription, repo: %c%s',
          'font-weight:bold',
          repoRef
        );
        subscription = EMPTY_SUBSCRIPTION;
      }
      const latestRelease = releases[subscription.ref];
      const enrichedItem = gitHubItemFactory(
        item,
        subscription,
        latestRelease ? new Date(latestRelease) : undefined
      );

      enrichedItem.labels.forEach(label => {
        if (label.name?.startsWith('Discipline: ')) {
          enrichedItem.discipline_refs.push(labelToRefFactory(label.name));
        } else if (label.name?.startsWith('Area: ')) {
          enrichedItem.area_refs.push(labelToRefFactory(label.name));
        }
      });

      enrichedItem.discipline_refs = uniq(enrichedItem.discipline_refs);
      enrichedItem.area_refs = uniq(enrichedItem.area_refs);

      map[ref] = enrichedItem;
      return map;
    }, {});
  }
);

// Sort the refs by stage to make sure they're processed in the right order by downstream selectors
const getGitHubRefs = createSelector(
  [readGitHubRefs, getEnrichedGithubItems],
  (gitHubRefs, enrichedMap) => {
    return gitHubRefs
      .slice()
      .sort(
        (a, b) =>
          stageIndices[enrichedMap[a]?.stage ?? Stages.NO_IDEA] -
          stageIndices[enrichedMap[b]?.stage ?? Stages.NO_IDEA]
      );
  }
);

export const getGitHub = createSelector(
  [getGitHubRefs, getEnrichedGithubItems],
  (githubRefs, githubMap) => ({
    refs: githubRefs,
    map: githubMap,
  })
);

export const getGithubMostRecentItemUpdate = createSelector(
  [readGithub],
  github => github.mostRecentItemUpdate
);

export const getCanPollForGithubChanges = createSelector(
  [getGithubMostRecentItemUpdate],
  mostRecentItemUpdate =>
    mostRecentItemUpdate != null &&
    isAfter(new Date(mostRecentItemUpdate), sub(new Date(), { weeks: 2 }))
);

export const getOpenTicket = createSelector(
  [readOpenTicketRef, getEnrichedGithubItems],
  (ticketRef, gitHubMap) => {
    if (!ticketRef) return null;

    const ticket = ticketRef.source === 'github' ? gitHubMap[ticketRef.relationRef] : null;

    return ticket || null;
  }
);

export const getPeopleAsDropdownOptions = createSelector(
  [getFilteredPeople, getFilter, getCurrentUserEmail],
  (people, filter, currentUserEmail) => {
    let currentUserOption;
    const options = people.map(person => {
      const isCurrentUser = person.email === currentUserEmail;

      const option = {
        value: person.email,
        label: isCurrentUser ? `Me (${person.name})` : person.name,
        active: filter?.users?.includes(person.email),
        avatar: person.avatar,
      };

      if (isCurrentUser) {
        currentUserOption = option;
      }

      return option;
    });

    const sortedOptions = options.sort((a, b) => naturalCompare(a.label, b.label));

    const currentUserIndex = sortedOptions.indexOf(currentUserOption);
    if (currentUserIndex !== -1) {
      sortedOptions.splice(currentUserIndex, 1);
      sortedOptions.unshift(currentUserOption);
    }

    return sortedOptions;
  }
);

const rollupEstimate = (a: GitHubItem, b: GitHubItem) => {
  if (!a.status.estimate) return b.status.estimate;
  if (!b.status.estimate) return a.status.estimate;
  return Math.max(a.status.estimate, b.status.estimate);
};

/**
 * Recursively walks through all GitHub items to establish parent-child relationships and set
 * metadata about assignee/team ownership.
 */
const getTicketsInHierarchy = createSelector(
  [getGitHub, getPeople, getWorkspaceDisciplinesMap, getWorkspaceTeamsMap, getWorkspaceAreasMap],
  (gitHub, people, disciplinesMap, teamsMap, areasMap) => {
    const stages = {} as Record<Stages, Record<string, GitHubItem>>;
    const itemsWithChildrenInAnyStage = new Set<string>();
    gitHub.refs.forEach(itemRef => {
      const item = gitHub.map[itemRef];
      if (!item) {
        console.warn(
          'A ref to a non-existent issue was found in the store: %c%s',
          'font-weight:bold',
          itemRef
        );
        return;
      }

      const stage = (stages[item.stage] ??= {});

      // If the issue has already been added as the parent of another issue in this stage we can
      // skip it
      if (stage[itemRef]) {
        return;
      }

      const clonedItem: GitHubItem = (stage[itemRef] ??= {
        ...findEntityRefs(item, people, disciplinesMap, teamsMap, areasMap),
        children: [],
      });

      // Recursively add parents until we're at the top level
      (function recursivelyAddParents(item: GitHubItem) {
        const parentItems = item.relationships.reduce<Array<GitHubItem>>(
          (parentItems, relationship) => {
            if (
              !(relationship.type === 'close' || relationship.type === 'parent') ||
              relationship.relationRef === item.relationRef // Don't allow circular references
            ) {
              return parentItems;
            }

            const parentItem: GitHubItem | undefined = gitHub.map[relationship.relationRef];
            if (parentItem) {
              const clonedParentItem: GitHubItem = (stage[parentItem.relationRef] ??= {
                ...findEntityRefs(parentItem, people, disciplinesMap, teamsMap, areasMap),
                stage: item.stage,
                status: {
                  ...parentItem.status,
                  stage: item.stage,
                  estimate: rollupEstimate(item, parentItem),
                },
                children: [],
              });
              if (!clonedParentItem.children!.includes(item)) {
                clonedParentItem.children!.push(item);
                itemsWithChildrenInAnyStage.add(clonedParentItem.relationRef);
              }
              parentItems.push(clonedParentItem);
              (item.parentRefs ??= []).push(parentItem.relationRef);

              // Allow child items to inherit labels from their parents
              clonedItem.labels = uniqBy(clonedItem.labels.concat(clonedParentItem.labels), 'name');
            }

            return parentItems;
          },
          []
        );

        if (parentItems.length) {
          parentItems.forEach(parentIssue => recursivelyAddParents(parentIssue));
        }
      })(clonedItem);

      Object.freeze(clonedItem);
    });

    const items = Object.values(stages).flatMap(stage => Object.values(stage));

    return {
      items,
      itemsWithChildrenInAnyStage,
    };
  }
);

export const getFilteredTickets = createSelector(
  [getFilter, getTicketsInHierarchy],
  (filter, { items, itemsWithChildrenInAnyStage }) => {
    return perfWrapStatement('devTicketsToList', `Processed ${items.length} github items`, () => {
      return devTicketsToList({
        items,
        itemsWithChildrenInAnyStage,
        filter,
      });
    });
  }
);

export const getPersonMatchingFilter = createSelector(
  [getPeopleMap, getFilter],
  (peopleMap, filter) => {
    const userKey = filter.users?.[0];
    return userKey ? peopleMap[userKey] : null;
  }
);

const MILLISECONDS_IN_A_MONTH = 1000 * 60 * 60 * 24 * 30;

export const getStagesWithTickets = createSelector([getFilteredTickets], tickets => {
  return perfWrapStatement(
    'getStagesWithTickets',
    `Processed ${tickets.length} tickets into ${allStages.length} lanes`,
    () => {
      // Using plain arithmetic is ~30x faster than date manipulation
      const now = +DateTime.now();

      return Object.values(stageMap)
        .map((stage, index, stages) => {
          let inheritedInvisibleStage = false;
          const shouldCombine = !stages.find(s => stage.combineInvisibleStages?.includes(s.key));
          const ticketsInStage = tickets
            .filter(ticket => {
              // Include tickets that match the stage exactly. Stage may also include
              // tickets from other stages, if included in `combineInvisibleStages`.
              const matchesLaneExactly = ticket.stage === stage.key;

              // this doesn't work right with circular fallbacks
              const matchesInvisibleStage = stage.combineInvisibleStages?.includes(ticket.stage);

              if (shouldCombine && matchesInvisibleStage) {
                inheritedInvisibleStage = true;
              }

              let matchesAgeFilter = true;
              if (
                ticket.stage === Stages.Released &&
                (!!ticket.completed_at || !!ticket.closed_at || !!ticket.updated_at)
              ) {
                matchesAgeFilter =
                  +(ticket.completed_at ?? ticket.closed_at ?? ticket.updated_at!) +
                    MILLISECONDS_IN_A_MONTH >
                  now;
              } else if (ticket.stage === Stages.Graveyard && !!ticket.closed_at) {
                matchesAgeFilter = +ticket.closed_at + MILLISECONDS_IN_A_MONTH > now;
              }

              return (matchesLaneExactly || matchesInvisibleStage) && matchesAgeFilter;
            })
            .sort((a, b) => {
              if ([Stages.ReadyForRelease, Stages.Released].includes(b.stage)) {
                return (
                  +(b.completed_at ?? 0) - +(a.completed_at ?? 0) ||
                  +(b.updated_at ?? 0) - +(a.updated_at ?? 0)
                );
              }

              return (
                naturalCompare(b.status.importance, a.status.importance) ||
                +(b.updated_at ?? 0) - +(a.updated_at ?? 0)
              );
            });

          return {
            ...stage,
            inheritedInvisibleStage,
            tickets: ticketsInStage,
          };
        })
        .filter(notNullOrUndefined);
    }
  );
});

type ArrayElement<ArrayType> = ArrayType extends ReadonlyArray<infer ElementType>
  ? ElementType
  : never;

export type StageWithTickets = ArrayElement<ReturnType<typeof getStagesWithTickets>>;

export const getStagingEnvList = createSelector(
  [getFilteredTickets, getWorkspaceEnvironments],
  (tickets, envs) => {
    return envs.map(env => {
      return {
        title: env.title,
        url: env.url,
        tickets: tickets.filter(ticket => {
          const isOutdated = [
            Stages.Released,
            Stages.Graveyard,
            Stages.ReadyForMerge,
            Stages.ReadyForMerge,
          ].includes(ticket.stage);

          return !isOutdated && ticket.labels.some(label => label.name === env.ref);
        }),
      };
    });
  }
);

export const getStages = (state: RootState) => state.devProcess.stages;

export const getGithubFetchHasErrors = (state: RootState) =>
  !!state.devProcess.githubFetchHasErrors;

export const getHasCheckedCache = (state: RootState) => state.devProcess.github.hasCheckedCache;
