import { stageIndices } from './devProcess.constants';
import {
  GitHubItem,
  Person,
  Discipline,
  StoryIssueClassification,
  Team,
  Stages,
  Area,
} from 'src/types';
import type { Filter } from './devProcess.reducer';
import { labelExists } from 'src/factories/github';

function itemMatchesFilter(filter: Filter, issue: GitHubItem): boolean {
  const matchDiscipline =
    !filter.disciplines ||
    (filter.disciplines.includes('?') && !issue.discipline_refs?.length) ||
    filter.disciplines.some(disciplineRef => {
      return issue.discipline_refs?.includes(disciplineRef) ?? false;
    });

  let matchProject = !filter.projects.length;
  if (filter.projects.length === 1 && filter.projects[0] === '?') {
    matchProject = issue.belongs_to_project_refs.length > 1 && !issue.products.length;
  } else if (filter.projects.length >= 1) {
    matchProject = filter.projects.some(projectRef => {
      let isMatch = filter.projects.some(projectRef =>
        issue.belongs_to_project_refs.includes(projectRef)
      );
      if (isMatch && issue.belongs_to_project_refs.length > 1) {
        isMatch = issue.products.includes(projectRef);
      }
      return isMatch;
    });
  }

  const matchTicketTypes =
    !filter.ticketTypes ||
    filter.ticketTypes.some(type => {
      return issue.classifications.includes(type as StoryIssueClassification);
    });

  const matchSources = !filter.sources.length || filter.sources.includes(issue.type);

  const matchUser =
    !filter.users.length ||
    (filter.users.length === 1 && filter.users[0] === '?' && !issue.assignees?.length) ||
    filter.users.some(
      userRef =>
        !!issue.assignees?.find(
          assignee =>
            assignee.externalReference[issue.source] === userRef || assignee.email === userRef
        )
    );

  const matchTeam =
    !filter.teams ||
    (filter.teams.includes('?') && !issue.team_refs?.length) ||
    filter.teams.some(team => {
      return issue.team_refs?.includes(team);
    });

  const matchSprint =
    !filter.sprints.length ||
    (filter.sprints.includes('in') && labelExists(issue.labels, 'in sprint')) ||
    (filter.sprints.includes('out') && !labelExists(issue.labels, 'in sprint'));

  const matchArea =
    !filter.areas ||
    (filter.areas.includes('?') && !issue.area_refs?.length) ||
    filter.areas.some(areaRef => {
      return issue.area_refs?.includes(areaRef) ?? false;
    });

  const passesTest =
    matchProject &&
    matchDiscipline &&
    matchTicketTypes &&
    matchSources &&
    matchUser &&
    matchTeam &&
    matchSprint &&
    matchArea;

  return passesTest;
}

export function findEntityRefs(
  item: GitHubItem,
  people: Array<Person>,
  disciplinesMap: Record<string, Discipline>,
  teamsMap: Record<string, Team>,
  areasMap: Record<string, Area>
) {
  return {
    ...item,
    assignees: (item.assignee_refs ?? []).reduce<Array<Person>>((assignees, ref) => {
      const person = people.find(person => person.externalReference[item.source] === ref);
      if (person) {
        assignees.push(person);
      }
      return assignees;
    }, []),
    reviewers: (item.reviewers_refs ?? []).reduce<Array<Person>>((reviewers, ref) => {
      const person = people.find(person => person.externalReference[item.source] === ref);
      if (person) {
        reviewers.push(person);
      }
      return reviewers;
    }, []),
    owners: (item.owners_refs ?? []).reduce<Array<Person>>((owners, ref) => {
      const person = people.find(person => person.externalReference[item.source] === ref);
      if (person) {
        owners.push(person);
      }
      return owners;
    }, []),
    disciplines: (item.discipline_refs ?? []).reduce<Array<Discipline>>((disciplines, ref) => {
      const discipline = disciplinesMap[ref];
      if (discipline) {
        disciplines.push(discipline);
      }
      return disciplines;
    }, []),
    blocking_disciplines: (item.blocking_discipline_refs ?? []).reduce<Array<Discipline>>(
      (disciplines, ref) => {
        const discipline = disciplinesMap[ref];
        if (discipline) {
          disciplines.push(discipline);
        }
        return disciplines;
      },
      []
    ),
    teams: (item.team_refs ?? []).reduce<Array<Team>>((teams, ref) => {
      const team = teamsMap[ref];
      if (team) {
        teams.push(team);
      }
      return teams;
    }, []),
    areas: (item.area_refs ?? []).reduce<Array<Area>>((areas, ref) => {
      const area = areasMap[ref];
      if (area) {
        areas.push(area);
      }
      return areas;
    }, []),
  };
}

const escapeRegExp = (source: string) => {
  const chars: Array<string> = [];
  for (let c of source) {
    if ('^$\\.*+?()[]{}|'.indexOf(c) !== -1) {
      chars.push('\\');
    }
    chars.push(c);
  }
  return chars.join('');
};

export function createSearchExpression(query: string = '') {
  return new RegExp(
    escapeRegExp(query).trim().normalize('NFKD').replaceAll(/(\s+)/g, '(?:[^s]*\\s+)'),
    'i'
  );
}

interface DevTicketParams {
  items: Array<GitHubItem>;
  itemsWithChildrenInAnyStage: Set<string>;
  filter: Filter;
}

export function devTicketsToList({
  items,
  itemsWithChildrenInAnyStage,
  filter,
}: DevTicketParams): Array<GitHubItem> {
  const searchExpression = createSearchExpression(filter.searchQuery);
  const matchesFilterAndSearch = (item: GitHubItem): boolean => {
    return searchExpression.test(item.searchBlob) && itemMatchesFilter(filter, item);
  };

  const topLevelInstanceCounts = {} as Record<string, number>;
  const seenTopLevelRefs = new Set<string>();
  const backfilledItems = {} as Record<string, Array<GitHubItem>>;
  const visibleItemStages = {} as Record<string, Set<Stages>>;

  interface RecursivelyFilterItemsOptions {
    includedRefs?: Set<string>;
    matchFilters?: boolean;
    topLevelHoistTarget?: Array<GitHubItem>;
  }

  const recursivelyFilterChildrenFactory = ({
    includedRefs,
    matchFilters = false,
  }: RecursivelyFilterItemsOptions = {}) => {
    const recursivelyFilterItems = (
      children: Array<GitHubItem>,
      hoistTarget?: Array<GitHubItem>
    ): Array<GitHubItem> => {
      return children.reduce<Array<GitHubItem>>((items, item) => {
        let shouldInclude: boolean;

        // If there are no children, but this issue isn't present with children in a different
        // stage, we should keep it
        if (!item.children?.length) {
          shouldInclude =
            (!itemsWithChildrenInAnyStage.has(item.relationRef) ||
              !!item.status.overrideHasRemainingWork ||
              (item.type === 'pull_request' && !item.status.isDone)) &&
            (!matchFilters || matchesFilterAndSearch(item));

          if (shouldInclude) {
            // We need to clone item here and below so we can safely mutate children
            item = { ...item };
          }
        } else {
          item = { ...item };
          item.children = recursivelyFilterItems(item.children!, hoistTarget ? items : undefined);

          const match = !matchFilters || matchesFilterAndSearch(item);

          if (hoistTarget) {
            if (!match && !!item.children.length) {
              Array.prototype.push.apply(hoistTarget, item.children);
              item.children = [];
            }
            shouldInclude = !!item.children.length || match;
          } else {
            shouldInclude =
              !!item.children.length || !itemsWithChildrenInAnyStage.has(item.relationRef) || match;
          }
        }

        if (shouldInclude) {
          includedRefs?.add(item.relationRef);
          items.push(item);
        }

        return items;
      }, []);
    };

    return recursivelyFilterItems;
  };

  const filteredItemRefs = new Set<string>();
  const filteredItems = items
    // Initial filtering pass - remove child items and keep count of the number of instances for
    // each item
    .reduce<Array<GitHubItem>>((items, item) => {
      const isTopLevelIssue = !item.parentRefs?.length;
      if (isTopLevelIssue) {
        topLevelInstanceCounts[item.relationRef] =
          (topLevelInstanceCounts[item.relationRef] ?? 0) + 1;

        // Clone the item since we'll be mutating its children
        items.push({ ...item });
      }

      return items;
    }, [])

    // Sort issues left-to-right by stage
    .sort((a, b) => stageIndices[a.stage] - stageIndices[b.stage])

    // Coarse filtering pass - remove childless items
    .reduce<Array<GitHubItem>>((items, item) => {
      if (item.parentRefs?.length) {
        return items;
      }

      if (!item.children?.length && !matchesFilterAndSearch(item)) {
        return items;
      }

      // Remove any top level issues with more than one instance if they don't have children,
      // so we can roll up to the rightmost stage
      if (topLevelInstanceCounts[item.relationRef] !== 1 && !item.children?.length) {
        if (
          item.status.overrideHasRemainingWork &&
          !seenTopLevelRefs.has(item.relationRef) // && matchesFilterAndSearch(item)
        ) {
          items.push(item);
          seenTopLevelRefs.add(item.relationRef);
        }

        return items;
      }

      // Recursively remove childless children that have children in other stages
      const recursivelyFilterItems = recursivelyFilterChildrenFactory();
      item.children &&= recursivelyFilterItems(item.children);
      if (item.children?.length || !itemsWithChildrenInAnyStage.has(item.relationRef)) {
        items.push(item);
      }

      return items;
    }, [])

    // Fine filtering pass - apply filters and hoist children with missing parents
    .reduce<Array<GitHubItem>>((items, item) => {
      const recursivelyFilterItems = recursivelyFilterChildrenFactory({
        includedRefs: filteredItemRefs,
        matchFilters: true,
      });
      item.children &&= recursivelyFilterItems(item.children, items);

      function pushItem(item: GitHubItem) {
        filteredItemRefs.add(item.relationRef);
        items.push(item);
        (visibleItemStages[item.relationRef] ??= new Set()).add(item.stage);
      }

      if (item.children?.length) {
        if (!matchesFilterAndSearch(item)) {
          item.children.forEach(childItem => {
            pushItem(childItem);
          });
          item.children = [];
        } else {
          pushItem(item);
        }
      } else if (
        !itemsWithChildrenInAnyStage.has(item.relationRef) ||
        // If the item has been artificially pushed back to a work stage, we should allow the first
        // item to come through to ensure it's placed in the leftmost stage
        (item.status.overrideHasRemainingWork &&
          matchesFilterAndSearch(item) &&
          !filteredItemRefs.has(item.relationRef))
      ) {
        pushItem(item);
      } else {
        // If there are multiple instances of this issue and all of them end up childless after the
        // filters have been applied, we need to backfill the first instance so it's not lost
        (backfilledItems[item.relationRef] ??= []).push(item);
      }

      return items;
    }, [])
    .concat(
      Object.entries(backfilledItems).reduce<Array<GitHubItem>>((backfilledItems, [ref, items]) => {
        if (!filteredItemRefs.has(ref) && matchesFilterAndSearch(items[0])) {
          backfilledItems.push(items[0]);
          return backfilledItems;
        }

        const visibleStages = visibleItemStages[ref];
        if (!visibleStages || visibleStages.size > 1) return backfilledItems;

        if (visibleStages.has(Stages.Released) || visibleStages.has(Stages.Graveyard)) {
          const nonReleasedOrGraveyardItems = items.filter(
            item => ![Stages.Released, Stages.Graveyard].includes(item.stage)
          );
          if (nonReleasedOrGraveyardItems.length) {
            backfilledItems.push(
              nonReleasedOrGraveyardItems[nonReleasedOrGraveyardItems.length - 1]
            );
          }
        }

        return backfilledItems;
      }, [])
    );

  return filteredItems;
}
