import type { JwtPayload } from 'jsonwebtoken';

export { AUTH_CLIENT_ID } from '../../../functions/src/utils/constants';

export interface CredentialJwtPayload extends Required<JwtPayload> {
  azp: string;
  email_verified: boolean;
  email: string;
  family_name: string;
  given_name: string;
  hd: string;
  name: string;
  picture: string;
}

export function decodeCredentialJwtPayload(credential: string): CredentialJwtPayload {
  const jwtEncodedPayload = credential.split('.')[1];
  return JSON.parse<CredentialJwtPayload>(atob(jwtEncodedPayload));
}
