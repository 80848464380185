import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { MetricRecord } from 'functions/src/datasources/github-gql';
import { fetchGitHubMetrics } from './serviceDesk.actions';

export enum GroupBy {
  months = 'months',
  weeks = 'weeks',
}

interface ServiceDeskState {
  fetchStatus: 'unfetched' | 'fetching' | 'fetched';
  groupBy: GroupBy;
  records: Array<MetricRecord>;
  showTrends: boolean;
}

let initialState: ServiceDeskState = {
  fetchStatus: 'unfetched',
  groupBy: GroupBy.months,
  records: [],
  showTrends: false,
};

const serviceDeskSlice = createSlice({
  name: 'serviceDesk',
  initialState,
  reducers: {
    setGroupBy: (state, action: PayloadAction<GroupBy>) => {
      state.groupBy = action.payload;
    },
    setShowTrends: (state, action: PayloadAction<boolean>) => {
      state.showTrends = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchGitHubMetrics.pending, state => {
      state.fetchStatus = 'fetching';
    });
    builder.addCase(fetchGitHubMetrics.fulfilled, (state, action) => {
      state.records = action.payload;
      state.fetchStatus = 'fetched';
    });
  },
});

export const { setGroupBy, setShowTrends } = serviceDeskSlice.actions;

export default serviceDeskSlice.reducer;
