import { configureStore } from '@reduxjs/toolkit';

import rootReducer from './rootReducer';
import { createAuthMiddleware, isAuthMiddlewareSupported } from './modules/auth.middleware';

const store = configureStore({
  reducer: rootReducer,
  // Disable immutableCheck, as the large store was getting painful to work with locally
  // https://github.com/reduxjs/redux-toolkit/issues/415
  // https://www.npmjs.com/package/redux-immutable-state-invariant
  middleware: getDefaultMiddleware => {
    const middleware = getDefaultMiddleware({
      immutableCheck: false,
    });

    if (isAuthMiddlewareSupported) {
      middleware.push(createAuthMiddleware());
    }

    return middleware;
  },
});

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./rootReducer', async () => {
    const newRootReducer = require('./rootReducer').default;
    store.replaceReducer(newRootReducer);
  });
}

export type AppDispatch = typeof store.dispatch;

export default store;
