import { combineReducers } from '@reduxjs/toolkit';

import devProcessReducer from './modules/devProcess.reducer';
import peopleReducer from './modules/people.reducer';
import workspaceReducer from './modules/workspace.reducer';
import metricsReducer from './modules/metrics.reducer';
import roadmapReducer from './modules/roadmap.reducer';
import serviceDeskReducer from './modules/serviceDesk.reducer';
import authSlice from './modules/auth';

const rootReducer = combineReducers({
  devProcess: devProcessReducer,
  people: peopleReducer,
  workspace: workspaceReducer,
  metrics: metricsReducer,
  roadmap: roadmapReducer,
  serviceDesk: serviceDeskReducer,
  [authSlice.name]: authSlice.reducer,
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
