import { createSelector } from 'reselect';
import type { RootState } from '../rootReducer';
import slice from './auth';
import { AUTH_CLIENT_ID, decodeCredentialJwtPayload } from './auth.utils';

export const getIsAuthScriptLoaded = (state: RootState) => state[slice.name].scriptLoaded;
export const getRedirectToAfterAuth = (state: RootState) => state[slice.name].redirectToAfterAuth;
export const getCurrentUserEmail = (state: RootState) => state[slice.name].email ?? '';

export const getCredentialJwtPayload = createSelector(
  [(state: RootState) => state[slice.name].credential],
  credential => {
    if (!credential) return null;

    return decodeCredentialJwtPayload(credential);
  }
);

export const getIsAuthenticated = createSelector(
  [getCredentialJwtPayload, () => Math.floor(Date.now() / 1000)],
  (jwtPayload, now) => {
    if (!jwtPayload) return false;

    if (jwtPayload.aud !== AUTH_CLIENT_ID) return false;

    return jwtPayload.nbf <= now && jwtPayload.exp > now;
  }
);

/**
 * @returns `RequestInit` object pre-filled with the `Authorization: Bearer ...` header if the
 * token is valid.
 *
 * If the token is expired or otherwise invalid, returns `undefined`.
 */
export const getApiOptionsWithAuthHeader = createSelector(
  [(state: RootState) => state[slice.name].credential, getIsAuthenticated],
  (credential, isAuthenticated): RequestInit | undefined => {
    if (!isAuthenticated) return;

    return {
      headers: {
        Authorization: `Bearer ${credential}`,
      },
    };
  }
);
