import { createSlice } from '@reduxjs/toolkit';
import { fetchRoadmapData } from './roadmap.actions';
import type { ProductVision, RoadmapPlan } from 'src/types';

type RoadmapState = {
  plans: Array<RoadmapPlan>;
  visions: Array<ProductVision>;
};

let initialState: RoadmapState = {
  plans: [],
  visions: [],
};

const roadmapSlice = createSlice({
  name: 'metrics',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchRoadmapData.fulfilled, (state, action) => {
      const { payload } = action;
      state.plans = payload.plans;
      state.visions = payload.visions;
    });
  },
});

export default roadmapSlice.reducer;
