import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from '../rootReducer';
import type { Person, PersonInHierarchy } from 'src/types';
import { naturalCompare } from 'src/utils/sort';

export const getPeopleFilter = (state: RootState) => state.people.filter;
export const getPeople = (state: RootState) => state.people.entries;
export const getHasInitializedPeople = (state: RootState) => state.people.hasInitialized;

export const getFilteredPeople = createSelector(
  [getPeople, getPeopleFilter],
  (people, filter): Array<Person> => {
    const searchQuery = filter.searchQuery?.toLowerCase();
    const filteredPeople = people.filter(person => {
      if (!filter.includeInactive && !person.meta.active) {
        return false;
      }

      if (searchQuery?.length) {
        return person.name.toLowerCase().includes(searchQuery);
      }
      return true;
    });

    const normallySortedPeople = filteredPeople.sort((a, b) => naturalCompare(a.name, b.name));

    if (filter.orderBy === 'alphanumeric') {
      return normallySortedPeople;
    }
    // Do some extra sorting to surface anomalities
    return normallySortedPeople.sort(
      (a, b) =>
        (b.meta.activeLeave ? 1 : -1) - (a.meta.activeLeave ? 1 : -1) ||
        (b.meta.hasPlannedLeave ? 1 : -1) - (a.meta.hasPlannedLeave ? 1 : -1) ||
        (b.imStatus.isSet ? 1 : -1) - (a.imStatus.isSet ? 1 : -1)
    );
  }
);

export const getPeopleMap = createSelector(getPeople, people => {
  return people.reduce((map, person) => {
    map[person.email] = person;
    return map;
  }, {});
});

export const getPeopleHierarchy = createSelector(getPeople, people => {
  const topLevelPeople: Array<PersonInHierarchy> = [];
  const visitorMap = new Map<number, PersonInHierarchy>();
  for (const person of people) {
    if (!person.externalReference.freshteamId) continue;

    let existingPerson = visitorMap.get(person.externalReference.freshteamId);
    if (existingPerson) {
      existingPerson = { ...existingPerson, ...person };
    } else {
      existingPerson = { ...person, directReports: [] };
      visitorMap.set(person.externalReference.freshteamId, existingPerson);
    }

    if (!person.externalReference.freshteamManagerId) {
      topLevelPeople.push(existingPerson);
      continue;
    }

    let existingParent = visitorMap.get(person.externalReference.freshteamManagerId);
    if (existingParent) {
      existingParent.directReports.push(existingPerson);
    } else {
      existingParent = { directReports: [existingPerson] } as any;
      visitorMap.set(person.externalReference.freshteamManagerId, existingParent as any);
    }
  }

  return topLevelPeople.sort((a, b) => b.directReports.length - a.directReports.length);
});

export const getSubtreeSizes = createSelector(getPeopleHierarchy, people => {
  return (function _getSubtreeSizes(
    people: Array<PersonInHierarchy>,
    m: WeakMap<PersonInHierarchy, number> = new WeakMap()
  ) {
    for (const person of people) {
      if (!person.directReports.length) {
        m.set(person, 1);
      } else {
        _getSubtreeSizes(person.directReports, m);
        const size = person.directReports.reduce((c, p) => c + m.get(p)!, 0);
        m.set(person, size);
      }
    }
    return m;
  })(people);
});
