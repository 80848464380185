import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { decodeCredentialJwtPayload } from './auth.utils';

export interface CredentialResponse {
  clientId: string;
  credential: string;
}
export interface AuthState {
  credential?: string;
  email?: string;
  scriptLoaded: boolean;
  redirectToAfterAuth: string;
}

const initialState: AuthState = {
  scriptLoaded: false,
  redirectToAfterAuth: '/',
};

const credentialStorageKey = 'credential';
try {
  const credential = localStorage.getItem(credentialStorageKey);
  if (credential) {
    initialState.credential = credential;
    const jwtPayload = decodeCredentialJwtPayload(credential);
    initialState.email = jwtPayload.email;
  }
} catch {}

const sliceName = 'auth';

export const login = createAction(
  `${sliceName}/login`,
  (payload: CredentialResponse, fromAuthMiddleware = false) => {
    return {
      payload,
      meta: {
        fromAuthMiddleware,
      },
    };
  }
);

export type LoginAction = ReturnType<typeof login>;

const authSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    scriptLoaded(state) {
      state.scriptLoaded = true;
    },
    setRedirectTo(state, action: PayloadAction<string>) {
      state.redirectToAfterAuth = action.payload;
    },
    logout(state) {
      state.email = undefined;
      state.credential = undefined;
    },
  },
  extraReducers: builder => {
    builder.addCase(login.type, (state, action: LoginAction) => {
      state.credential = action.payload.credential;

      const jwtPayload = decodeCredentialJwtPayload(action.payload.credential);

      state.email = jwtPayload.email;

      try {
        localStorage.setItem(credentialStorageKey, action.payload.credential);
      } catch {}
    });
  },
});

export const logout = authSlice.actions.logout;

export const { scriptLoaded } = authSlice.actions;

export default authSlice;
