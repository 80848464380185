import { createSlice } from '@reduxjs/toolkit';
import { Stages, SourceType, Discipline, Team } from 'src/types';
import type { IntegrationSubscription } from 'types/common';
import {
  fetchTeams,
  smartlyInitializeAllDataSources,
  fetchEnvironments,
  setLoadingMaxProgress,
  incrementLoadingProgress,
  incrementLoadingMaxProgress,
} from './workspace.actions';

/**
 * Determines what kind of data presentation we're looking at
 * Expect this to increase to different styles beyond kanban later.
 */

export interface Stage {}

export interface Layout {
  ref: string;
  name: string;
  emoji?: string;
  lanes: Array<string>;
}

export interface Lane {
  ref: string;
  stage: Stages;
  title: string;
  merge_with_invisible_stages: Array<string>;
}

/**
 * Labels are used for classifying incoming data into stages
 */
export interface Label {
  ref: string;
  name: string;
  color: string;
  team_owner_ref: string;
  aliases: Array<string>;
}

/** IntegrationLabels could be useful as aliases to internal Labels? */
interface IntegrationLabel {
  ref: string;
  name: string;
  color: string;
}

/**
 * Mocked Basics of an oAuth integration with services like Github etc.
 */
export interface Integration {
  ref: string;
  source: SourceType;
  enabled: boolean;
  /** By default, which teams should be assigned to the imported data? */
  default_team_owners_ref: Array<string>;
  labels: Array<IntegrationLabel>;
  /** Github repos, Trello boards, Basecamp projects, etc... */
  subscriptions: Array<IntegrationSubscription>;
}

// export interface ProjectReference {
//   id: string;
//   integration_ref: string;
// }

/**
 * Projects is anything a team works on -- solo team, or together.
 */
export interface Project {
  ref: string;
  name: string;
  // references: Array<ProjectReference>;
  inferred_discipline_refs: Array<string>;
  image?: string;
  imageSrcSet?: string;
}

// todo Move this from devProcess slice maybe
// interface Stage {
//   key: Stages;
//   title: string;
//   sortorder: number;
//   titleWhenCombined?: string;
//   inheritedInvisibleStage?: boolean;
//   combineInvisibleStages?: Array<Stages>;
// }

interface StagingEnv {
  ref: string;
  title: string;
  url: string;
}

interface Workspace {
  meta: {
    isInitialized: boolean;
    loadingProgress: number;
    loadingMaxProgress: number;
  };
  environments: {
    map: {
      [key: string]: StagingEnv;
    };
    refs: Array<string>;
  };
  integrations: {
    map: {
      [key: string]: Integration;
    };
    refs: Array<string>;
  };
  disciplines: {
    map: {
      [key: string]: Discipline;
    };
    refs: Array<string>;
  };
  projects: {
    map: {
      [key: string]: Project;
    };
    refs: Array<string>;
  };
  teams: {
    map: {
      [key: string]: Team;
    };
    refs: Array<string>;
  };
}

const initialState: Workspace = {
  meta: {
    isInitialized: false,
    loadingProgress: 0,
    loadingMaxProgress: 0,
  },
  environments: {
    map: {},
    refs: [],
  },
  disciplines: {
    map: {},
    refs: [],
  },
  projects: {
    map: {
      timely: {
        ref: 'timely',
        name: 'Timely',
        image: '/logos/timely_rounded.svg',
        inferred_discipline_refs: [
          'backend',
          'frontend',
          'android',
          'iphone',
          'ai',
          'devops',
          'tracker-macos',
          'tracker-win',
          'tracker-browsers',
        ],
      },
      'second-brain': {
        ref: 'second-brain',
        name: 'Second Brain',
        image: '/logos/glue_rounded.svg',
        inferred_discipline_refs: [
          'backend',
          'frontend',
          'android',
          'iphone',
          'ai',
          'devops',
          'tracker-macos',
          'tracker-win',
          'tracker-browsers',
        ],
      },
    },
    refs: ['timely', 'second-brain'],
  },
  integrations: {
    map: {
      github: {
        ref: 'github',
        source: 'github',
        enabled: true,
        default_team_owners_ref: [],
        labels: [],
        subscriptions: [
          // Backend
          {
            ref: 'Timely/timely',
            subscribed: true,
            belongs_to_project_refs: ['timely'],
            discipline_refs: ['backend'],
          },
          {
            ref: 'Timely/identity',
            subscribed: true,
            belongs_to_project_refs: ['timely'],
            discipline_refs: ['backend'],
          },
          {
            ref: 'Timely/dewo',
            subscribed: true,
            belongs_to_project_refs: [],
            discipline_refs: ['backend'],
          },
          {
            ref: 'Timely/memory-api-spec',
            subscribed: true,
            belongs_to_project_refs: ['timely'],
            discipline_refs: ['backend'],
          },
          // Frontend
          {
            ref: 'Timely/qa-dashboard',
            subscribed: true,
            belongs_to_project_refs: ['timely'],
            discipline_refs: ['frontend'],
            merge_means_released: true,
          },
          {
            ref: 'Timely/company-dashboard',
            subscribed: true,
            belongs_to_project_refs: ['timely'],
            discipline_refs: ['frontend'],
            merge_means_released: true,
          },
          {
            ref: 'Timely/Frontend',
            subscribed: true,
            belongs_to_project_refs: ['timely'],
            discipline_refs: ['frontend'],
          },
          {
            ref: 'Timely/dewo-frontend',
            subscribed: true,
            belongs_to_project_refs: [],
            discipline_refs: ['frontend'],
          },
          {
            ref: 'Timely/Mac-Electron',
            subscribed: true,
            belongs_to_project_refs: ['timely'],
            discipline_refs: ['frontend'],
            merge_means_released: true,
          },
          // Android
          {
            ref: 'Timely/android',
            subscribed: true,
            belongs_to_project_refs: ['timely', 'second-brain'],
            discipline_refs: ['android'],
          },
          // iPhone
          {
            ref: 'Timely/Timely-iOS',
            subscribed: true,
            belongs_to_project_refs: ['timely'],
            discipline_refs: ['iphone'],
          },
          // memory tracker, macos
          {
            ref: 'Timely/Memory-macOS',
            subscribed: true,
            belongs_to_project_refs: ['timely', 'second-brain'],
            discipline_refs: ['tracker-macos'],
          },
          {
            ref: 'Timely/dewo-iOS',
            subscribed: true,
            belongs_to_project_refs: [],
            discipline_refs: ['iphone'],
          },
          // memory tracker windows
          {
            ref: 'Timely/memory-tracker-windows',
            subscribed: true,
            belongs_to_project_refs: ['timely', 'second-brain'],
            // obvs do this better for this special repo
            discipline_refs: ['tracker-win'],
          },
          // memory tracker, chrome
          {
            ref: 'Timely/memory-browser-extension',
            subscribed: true,
            belongs_to_project_refs: ['timely', 'second-brain'],
            discipline_refs: ['tracker-chrome'],
          },
          // DevOps
          {
            ref: 'Timely/memory-deployment',
            subscribed: true,
            belongs_to_project_refs: [],
            discipline_refs: ['iphone'],
          },
          // AI
          {
            ref: 'Timely/ai-timely',
            subscribed: true,
            belongs_to_project_refs: ['timely'],
            discipline_refs: ['ai'],
          },
          {
            ref: 'Timely/ai-dewo',
            subscribed: true,
            belongs_to_project_refs: [],
            discipline_refs: ['ai'],
          },
          {
            ref: 'Timely/ai-tools',
            subscribed: true,
            belongs_to_project_refs: ['timely'],
            discipline_refs: ['ai'],
          },
          {
            ref: 'Timely/memory-api-spec',
            subscribed: true,
            belongs_to_project_refs: ['timely'],
            discipline_refs: ['backend'],
          },
          {
            ref: 'Timely/project-management',
            subscribed: true,
            belongs_to_project_refs: ['timely', 'second-brain'],
            discipline_refs: [],
          },
          {
            ref: 'Timely/memory.ai',
            subscribed: true,
            belongs_to_project_refs: ['timely'],
            discipline_refs: ['growth'],
          },
          {
            ref: 'Timely/design',
            subscribed: true,
            belongs_to_project_refs: ['timely', 'second-brain'],
            discipline_refs: ['design'],
          },
          {
            ref: 'Timely/company-dashboard',
            subscribed: true,
            belongs_to_project_refs: ['timely', 'second-brain'],
            discipline_refs: ['frontend'],
          },
          {
            ref: 'Timely/timely-automation',
            subscribed: true,
            belongs_to_project_refs: ['timely'],
            discipline_refs: ['qa'],
          },
          {
            ref: 'Timely/webflow',
            subscribed: true,
            belongs_to_project_refs: ['timely', 'second-brain'],
            discipline_refs: ['website'],
          },
          {
            ref: 'Timely/glue',
            subscribed: true,
            belongs_to_project_refs: ['second-brain'],
            discipline_refs: ['backend'],
          },
          {
            ref: 'Timely/analytics',
            subscribed: true,
            belongs_to_project_refs: ['timely', 'second-brain'],
            discipline_refs: [],
          },
        ],
      },
    },
    refs: ['github'],
  },
  teams: {
    map: {
      optimize: {
        ref: 'optimize',
        name: 'Optimize',
        emoji: '💡',
      },
      enable: {
        ref: 'enable',
        name: 'Enable',
        emoji: '🛠️',
      },
      expand: {
        ref: 'expand',
        name: 'Expand',
        emoji: '🚀',
      },
      deus: {
        ref: 'deus',
        name: 'Deus',
        emoji: '👑',
      },
      gadget: {
        ref: 'gadget',
        name: 'Gadget',
        emoji: '📱',
      },
    },
    refs: ['optimize', 'enable', 'expand', 'deus', 'gadget'],
  },
};

const workspaceSlice = createSlice({
  name: 'workspace',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchTeams.fulfilled, (state, action) => {
      const refs = action.payload.map(({ ref }) => ref);
      const map = action.payload.reduce((map, team) => {
        map[team.ref] = team;
        return map;
      }, {});

      state.disciplines.refs = refs;
      state.disciplines.map = map;
    });

    builder.addCase(fetchEnvironments.fulfilled, (state, action) => {
      const refs = action.payload.map(({ ref }) => ref);
      const map = action.payload.reduce((map, env) => {
        map[env.ref] = env;
        return map;
      }, {});

      state.environments.refs = refs;
      state.environments.map = map;
    });

    builder.addCase(smartlyInitializeAllDataSources.fulfilled, (state, action) => {
      state.meta.isInitialized = true;
    });

    builder.addCase(incrementLoadingProgress, (state, action) => {
      state.meta.loadingProgress += 1;
    });

    builder.addCase(setLoadingMaxProgress, (state, action) => {
      state.meta.loadingMaxProgress = action.payload;
    });

    builder.addCase(incrementLoadingMaxProgress, (state, action) => {
      state.meta.loadingMaxProgress += 1;
    });
  },
});

export default workspaceSlice.reducer;
