import { differenceInCalendarDays, isBefore } from 'date-fns';
import { memoize, uniq, uniqBy } from 'lodash';
import issueParser from 'issue-parser';
import {
  GitHubItem,
  SourceType,
  Stages,
  StoryIssueClassification,
  StoryIssueRelationship,
  GitHubItemStatus,
} from '../types';
import type { Issue, Label, Maybe, PullRequest } from '@octokit/graphql-schema';
import type { IntegrationSubscription } from 'types/common';
import { notNullOrUndefined } from '../utils';
import environments from '../../__mocks__/api/environments.json';
import { DateTime } from 'luxon';

type LabelBase = Pick<Label, 'name' | 'color'>;

export const labelToRefFactory = memoize((label: string) =>
  label
    .toLowerCase()
    .replace(/(discipline|area): /gi, '')
    .replace(/[^a-z]+/gi, '-')
);

const labelToDisciplineMap = {
  'Blocked by Android': 'android',
  'Blocked by Backend': 'backend',
  'Blocked by Business': 'business',
  'Blocked by Design': 'design',
  'Blocked by iPhone': 'iphone',
  'Blocked by Web': 'frontend',
} as const;

const matchLabelsToDisciplines = (labels: Array<Maybe<LabelBase>>, onlyBlockers = false) => {
  return labels
    .filter(label => {
      if (!label?.name) return false;

      const existsInMap = !!labelToDisciplineMap[label.name];
      if (onlyBlockers) {
        return label.name.includes('Blocked by') && existsInMap;
      }
      return existsInMap;
    })
    .map(label => labelToDisciplineMap[label!.name])
    .filter(notNullOrUndefined);
};

export function getOrgAndRepoFromGithubUrl(url: string) {
  const urlPieces = url.split('/');
  return `${urlPieces[3]}/${urlPieces[4]}`;
}

export function getOrgAndRepoFromRef(ref: string): { owner: string; repo: string } {
  const parts = ref.split('/');
  return {
    owner: parts[0] || 'Timely',
    repo: parts[1] || '',
  };
}

export const labelExists = (
  labels:
    | Maybe<Array<Maybe<LabelBase>>>
    | Array<{
        name?: string | undefined;
        color?: string | undefined;
      }> = [],
  search = ''
): boolean => {
  const string = search.toLowerCase();
  return !!labels?.some(label => label?.name?.toLowerCase().includes(string));
};

export const isPullRequest = memoize(
  (item: Issue | PullRequest): item is PullRequest => item.__typename === 'PullRequest',
  item => item.id
);

export const getStatusForItem = (
  item: Issue | PullRequest,
  subscription: IntegrationSubscription,
  releaseDate?: Date
): GitHubItemStatus => {
  const now = new Date();

  const daysOpen = differenceInCalendarDays(now, new Date(item.createdAt));
  const daysWithoutActivity = differenceInCalendarDays(now, new Date(item.updatedAt));
  const daysSinceMerge = isPullRequest(item)
    ? differenceInCalendarDays(now, new Date(item.mergedAt))
    : 0;

  const isDraftPR = isPullRequest(item) && item.isDraft;
  const isMerged = item.state === 'MERGED';
  const wasMergedLongAgo = isMerged && daysSinceMerge > 14; // Assume release if merged more than 14 days ago
  const isReleased =
    (isMerged && subscription.merge_means_released) ||
    wasMergedLongAgo ||
    labelExists(item.labels?.nodes, 'Dev: Released') ||
    (isMerged &&
      !!releaseDate &&
      isPullRequest(item) &&
      isBefore(new Date(item.mergedAt), releaseDate));
  const isClosed = item.state === 'CLOSED' || isMerged;
  const isIssueOrApprovedPR =
    item.repository?.name !== 'Frontend' ||
    !isPullRequest(item) ||
    item.latestReviews?.nodes?.some(review => review?.state === 'APPROVED');

  const isApprovedByQA = !isClosed && labelExists(item.labels?.nodes, 'qa: accepted');
  const isQAWorkingOnIt = !isClosed && labelExists(item.labels?.nodes, 'qa: in progress');
  const hasQARejected = !isClosed && labelExists(item.labels?.nodes, 'qa: rejected');
  const isQAOnHold = !isClosed && labelExists(item.labels?.nodes, 'qa: awaiting build');
  const isQABlocked = !isClosed && labelExists(item.labels?.nodes, 'qa: awaiting feedback');
  const isQANeeded = !isClosed && !labelExists(item.labels?.nodes, 'qa: Not needed');
  const isReadyForQA =
    !isClosed && labelExists(item.labels?.nodes, 'qa: ready for review') && isIssueOrApprovedPR;
  const isQAInvolved = isQANeeded && (isReadyForQA || isQAWorkingOnIt);
  const hasQABeenInvolved =
    isQANeeded &&
    (isApprovedByQA ||
      isQAWorkingOnIt ||
      hasQARejected ||
      isQAOnHold ||
      isQABlocked ||
      isReadyForQA);
  const hasWIPLabel =
    labelExists(item.labels?.nodes, 'dev: wip') || labelExists(item.labels?.nodes, 'design: wip');
  // TODO: Should we still fetch reviewers?
  const hasReviewers = false; //!!issue.requested_reviewers && issue.requested_reviewers.length > 0;
  const hasReviewReadyLabel = labelExists(item.labels?.nodes, 'dev: ready for review');
  const isReviewable = !isDraftPR && (hasReviewReadyLabel || hasReviewers);
  const isOpenIssue = !isPullRequest(item) && item.state === 'OPEN';
  const isLongRunning = !isOpenIssue && !isClosed && daysOpen >= 30;
  const isStale = !isOpenIssue && !isClosed && !isReleased && !isMerged && daysWithoutActivity >= 7;

  const isBlockedInQA = !isClosed && (isQAOnHold || isQABlocked);
  const isBlocked = labelExists(item.labels?.nodes, 'blocked by') && !isClosed;
  // const isTriageVerified = labelExists(item.labels?.nodes, 'triage: verified bug');
  // const isTriageStuck = labelExists(item.labels?.nodes, 'triage: cannot reproduce');
  const isEpic = labelExists(item.labels?.nodes, 'epic');
  const isImportantToSupport = labelExists(item.labels?.nodes, 'Support: High Priority');
  const isImportantToGrowth = labelExists(item.labels?.nodes, 'Growth: High Priority');
  const hasCustomerSpectators = labelExists(item.labels?.nodes, 'Support: Update Customer');
  const isNotable =
    isEpic ||
    labelExists(item.labels?.nodes, 'Release: Notable') ||
    labelExists(item.labels?.nodes, 'Release: Significant');
  const overrideHasRemainingWork = labelExists(item.labels?.nodes, 'Dev: Ready for development');

  const severity =
    +(
      item.labels?.nodes
        ?.find(label => label?.name.includes('Severity'))
        ?.name?.replace('Severity ', '') ?? 0
    ) || null;

  const importance = labelExists(item.labels?.nodes, 'Priority score: 100+ URGENT!')
    ? 100
    : labelExists(item.labels?.nodes, 'Priority score: 90-100')
    ? 95
    : labelExists(item.labels?.nodes, 'Priority score: 80-89')
    ? 85
    : labelExists(item.labels?.nodes, 'Priority score: 70-79')
    ? 75
    : labelExists(item.labels?.nodes, 'Priority score: 60-69')
    ? 65
    : labelExists(item.labels?.nodes, 'Priority score: 50-59')
    ? 55
    : labelExists(item.labels?.nodes, 'Priority score: 40-49')
    ? 45
    : labelExists(item.labels?.nodes, 'Priority score: 30-39')
    ? 35
    : labelExists(item.labels?.nodes, 'Priority score: 20-29')
    ? 25
    : labelExists(item.labels?.nodes, 'Priority score: 10-19')
    ? 15
    : labelExists(item.labels?.nodes, 'Priority score: 0-10')
    ? 5
    : severity === 1
    ? 100
    : severity === 2
    ? 75
    : severity === 3
    ? 55
    : severity === 4
    ? 35
    : severity === 5
    ? 15
    : isImportantToSupport || isImportantToGrowth
    ? 65
    : 0;

  const importanceLabel = importance
    ? item.labels?.nodes
        ?.find(label => label?.name.includes('Priority score'))
        ?.name?.replace('Priority score:', '') ?? null
    : isImportantToGrowth
    ? `Growth Priority`
    : isImportantToSupport
    ? `Success Priority}`
    : null;

  const estimateRegExp = /^Est: (\d+)/i;
  const estimateLabel = item.labels?.nodes?.find(
    label => label?.name && estimateRegExp.test(label.name)
  )?.name;
  const estimate = estimateLabel ? estimateLabel.match(estimateRegExp)?.[1] ?? null : null;

  const triageLabel =
    item.labels?.nodes
      ?.find(label => label?.name.includes('Triage:'))
      ?.name?.replace('Triage:', '') || null;

  // const estimate = ....todo, split est label on -, convert to numbers, find average.

  const isMaxPriority = importance >= 100;
  const isImportant =
    (importance >= 50 && !isMaxPriority) || isImportantToSupport || isImportantToGrowth;
  const isCritical = !!severity && +severity === 1;
  // const isSevereButNotCritical = !!severity && [2].includes(severity);
  const isUpNext = isOpenIssue && labelExists(item.labels?.nodes, 'Dev: Up Next');
  const isInDiscovery = isOpenIssue && labelExists(item.labels?.nodes, 'Dev: In Discovery');
  const isPriority = isOpenIssue && (isMaxPriority || isCritical);
  const passesMinRanking = importance > 0 || (!!severity && +severity <= 5);
  const isTriageNew = isOpenIssue && labelExists(item.labels?.nodes, 'triage: new');
  const isBacklog = isOpenIssue && !isMaxPriority && !isTriageNew && !hasQABeenInvolved;

  // Final decision makers:
  const isWorkInProgress =
    !isClosed && (hasWIPLabel || (isPullRequest(item) && (!hasReviewers || isDraftPR)));
  const isWaitingOnQA = isQANeeded && !isClosed && isQAWorkingOnIt;
  const isWaitingOnCodeReview =
    isReviewable &&
    !hasWIPLabel &&
    !isClosed &&
    !isApprovedByQA &&
    !isWaitingOnQA &&
    !hasQARejected;
  const isMergeReady = !isClosed && (!isQANeeded || isApprovedByQA);
  const isReadyForRelease = isMerged || labelExists(item.labels?.nodes, 'dev: ready for release');

  let messages: Array<any> = [];

  if (isQABlocked) {
    messages.push({
      label: 'QA Blocked',
      variant: 'danger',
    });
  }

  // if (isClosed && !isReleased && !isMerged) {
  //   messages.push({
  //     label: 'Discarded',
  //     variant: 'warning',
  //   });
  // }

  // if (isReleased) {
  //   messages.push({
  //     label: 'Released',
  //     variant: 'success',
  //   });
  // }

  // if (isLongRunning) {
  //   messages.push({
  //     label: 'Long running',
  //     variant: 'danger',
  //   });
  // }

  const stage: Stages = isBlocked
    ? Stages.Blocked
    : isReleased
    ? Stages.Released
    : isReadyForRelease
    ? Stages.ReadyForRelease
    : isMergeReady
    ? Stages.ReadyForMerge
    : isQAWorkingOnIt
    ? Stages.InQA
    : isBlockedInQA
    ? Stages.isBlockedInQA
    : isReadyForQA
    ? Stages.ReadyForQA
    : hasQARejected
    ? Stages.isRejectedByQA
    : isWaitingOnCodeReview
    ? Stages.InCodeReview
    : isWorkInProgress
    ? Stages.WorkInProgress
    : isPriority
    ? Stages.Priority
    : isUpNext
    ? Stages.UpNext
    : isInDiscovery
    ? Stages.InDiscovery
    : isBacklog && passesMinRanking
    ? Stages.Backlog
    : isBacklog && !passesMinRanking
    ? Stages.Icebox
    : isTriageNew
    ? Stages.Inbox
    : isClosed && !isMerged
    ? Stages.Graveyard
    : Stages.NO_IDEA;

  return {
    messages,
    stage,
    isEpic,
    isBlocked,
    daysOpen,
    isStale,
    isLongRunning,
    isImportant,
    isNotable,
    isMaxPriority,
    importance,
    importanceLabel,
    severity,
    estimate: estimate ? +estimate : undefined,
    daysWithoutActivity,
    hasCustomerSpectators,
    triageLabel,
    isDone: isClosed || isMerged || isReleased,
    isReleased,
    overrideHasRemainingWork,
    isQAInvolved,
  };
};

export type ItemStatus = ReturnType<typeof getStatusForItem>;

const parse = issueParser('github', { actions: { parent: ['child of'] } });

export const extractRelationships = (item: Issue | PullRequest): Array<StoryIssueRelationship> => {
  // todo: title reference
  // todo: Attached links? Parsed links?
  // todo: Refs to other github issues, ESPECIALLY the ones that
  const orgRepo = getOrgAndRepoFromGithubUrl(item.url);

  // URLs may be on the form "child of <https://...>" so we need to strip surrounding clamps
  const sanitizedBody = (item.body || '').replace(/[<>]/g, '');
  const result = parse(sanitizedBody);

  const actions = Object.keys(result.actions).reduce<Array<StoryIssueRelationship>>(
    (arr, actionKey) => {
      const action = result.actions[actionKey];
      const actionTaken = action.map(action => {
        return {
          relationRef: `${action.slug || orgRepo}/${action.issue}`,
          type: actionKey as StoryIssueRelationship['type'],
          source: 'github' as SourceType,
        };
      });

      arr.push(...actionTaken);
      return arr;
    },
    []
  );

  const ghReferences = result.refs.map(ref => {
    return {
      relationRef: `${ref.slug || orgRepo}/${ref.issue}`,
      source: 'github' as SourceType,
    };
  });

  return (
    uniqBy([...actions, ...ghReferences], 'relationRef')
      // Remove circular references
      .filter(({ relationRef }) => relationRef !== `${orgRepo}/${item.number}`)
  );
};

export const createGithubRef = (item: Issue | PullRequest): string =>
  `${getOrgAndRepoFromGithubUrl(item.url)}/${item.number}`;

export const gitHubItemFactory = (
  item: Issue | PullRequest,
  subscription: IntegrationSubscription,
  releaseDate?: Date
): GitHubItem => {
  const status = getStatusForItem(item, subscription, releaseDate);
  const orgRepo = getOrgAndRepoFromGithubUrl(item.url);

  const links: GitHubItem['links'] = [
    {
      url: item.url,
      source: 'github',
      title: `${orgRepo}/issue#${item.number}`,
    },
  ];
  const bucketName = orgRepo;

  if (isPullRequest(item)) {
    if (orgRepo === 'Timely/Frontend') {
      const revision = item.headRefOid?.slice(0, 7);
      links.push({
        url: `https://app.timelyapp.com/4301/?revision=${revision}`,
        title: `Preview build #${revision}`,
        source: 'timely' as SourceType,
      });
    }
  }

  const labels: Array<Maybe<LabelBase>> = item.labels?.nodes?.slice() ?? [];
  if (subscription.discipline_refs.includes('qa')) {
    labels.push({
      name: 'Discipline: QA',
      color: '#7057ff',
    });
  }

  const activeEnvs: Array<typeof environments[number]> = [];
  const classificationFromLabels: Array<StoryIssueClassification> = [];
  const teamsFromLabels: Array<string> = [];
  const products: Array<string> = [];

  for (const label of labels.slice()) {
    if (label?.name?.startsWith('Env:')) {
      const env = environments.find(env => env.ref === label.name);
      if (env) {
        activeEnvs.push(env);
        links.push({
          url: env.url,
          title: `Test on ${env.title}`,
          source: 'timely' as SourceType,
        });
      }
    } else if (label?.name?.startsWith('Type:')) {
      classificationFromLabels.push(
        label.name.toLowerCase().replace('type: ', '') as StoryIssueClassification
      );
    } else if (label?.name?.startsWith('Product:')) {
      products.push(label.name.toLowerCase().replace('product: ', '').replaceAll(' ', '-'));
      labels.splice(labels.indexOf(label), 1);
    }
    // else if (label?.name?.startsWith('Team:')) {
    //   teamsFromLabels.push(
    //     label.name.toLowerCase().replace('team: ', '') as StoryIssueClassification
    //   );
    // }
  }

  // Infer the product from the repository if not explicitly defined
  if (!products.length && subscription.belongs_to_project_refs.length === 1) {
    products.push(subscription.belongs_to_project_refs[0]);
  }

  // Assume issue is feature, if unlabeled
  // NB: This isn't always ideal, instead build tools to encourage & support proper tagging, always
  const classifications = classificationFromLabels.length
    ? classificationFromLabels
    : ['feature' as StoryIssueClassification];

  const blocking_discipline_refs = uniq(matchLabelsToDisciplines(labels, true));

  const type = isPullRequest(item) ? 'pull_request' : 'issue';
  const bucketPath = `${bucketName}, ${type === 'pull_request' ? `PR #` : 'Issue #'}${item.number}`;

  const gitHubItem: GitHubItem = {
    id: item.number,
    relationRef: `${orgRepo}/${item.number}`,
    source: 'github' as SourceType,
    bucket_path: bucketPath,
    belongs_to_project_refs: subscription.belongs_to_project_refs,
    products,
    // Who is responsible for blocking this?
    blocking_discipline_refs,
    // "Discipline: X" labels or subscription config determine which team is responsible
    discipline_refs: uniq([
      ...(subscription.discipline_refs || []),
      ...matchLabelsToDisciplines(labels),
      status.isQAInvolved ? 'qa' : undefined,
    ]).filter(notNullOrUndefined),
    area_refs: [],
    team_refs: teamsFromLabels,
    classifications,
    title: item.title,
    type,
    started_at: item.createdAt ? DateTime.fromISO(item.createdAt) : undefined,
    updated_at: item.updatedAt ? DateTime.fromISO(item.updatedAt) : undefined,
    closed_at: item.closedAt ? DateTime.fromISO(item.closedAt) : undefined,
    stage: status.stage,
    links: uniqBy(links, 'url'),
    labels: labels
      .filter(label => !!label?.name)
      .map(label => ({
        name: label!.name,
        color: `#${label!.color}`,
      })),
    messages: [],
    comments: [],
    assignee_refs:
      item.assignees.nodes?.map(assignee => assignee?.login).filter(notNullOrUndefined) ?? [],
    reviewers_refs: [],

    owners_refs: [],
    relationships: extractRelationships(item),
    status,
    searchBlob: [item.title, bucketPath, ...labels.map(label => label?.name ?? '')]
      .join()
      .trim()
      .normalize('NFKD'),
  };

  if (isPullRequest(item)) {
    gitHubItem.completed_at = item.mergedAt ? DateTime.fromISO(item.mergedAt) : undefined;
    gitHubItem.reviewers_refs = [];
    // TODO: Do we still want to fetch reviewers?
    // issueOrPullRequest.requested_reviewers
    //   ?.map(reviewer => reviewer?.login)
    //   .filter(notNullOrUndefined) ?? [];
  }

  return gitHubItem;
};
