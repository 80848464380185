import { Stages } from 'src/types';

export const stageIndices = Object.keys(Stages).reduce((stageIndices, key, index) => {
  stageIndices[key] = index;
  return stageIndices;
}, {} as Record<Stages, number>);

export const stageMap: {
  [key: string]: {
    key: Stages;
    title: string;
    sortorder?: number;
    titleWhenCombined?: string;
    inheritedInvisibleStage?: boolean;
    combineInvisibleStages?: Array<Stages>;
  };
} = {
  [Stages.Inbox]: {
    key: Stages.Inbox,
    title: 'Inbox',
  },
  [Stages.Icebox]: {
    key: Stages.Icebox,
    title: 'Icebox/Unprioritized',
  },
  [Stages.Backlog]: {
    key: Stages.Backlog,
    title: Stages.Backlog,
    // combineInvisibleStages: [Stages.Icebox],
  },
  [Stages.InDiscovery]: {
    key: Stages.InDiscovery,
    title: 'In Discovery',
    // combineInvisibleStages: [Stages.Priority],
  },
  [Stages.UpNext]: {
    key: Stages.UpNext,
    title: 'Up Next',
    // combineInvisibleStages: [Stages.Priority],
  },
  [Stages.Priority]: {
    key: Stages.Priority,
    title: 'Priority ASAP!',
  },
  // DesignWIP

  [Stages.Blocked]: {
    key: Stages.Blocked,
    title: 'Blocked (or Blocking)',
  },
  [Stages.WorkInProgress]: {
    key: Stages.WorkInProgress,
    title: 'Work in progress',
  },
  [Stages.InCodeReview]: {
    key: Stages.InCodeReview,
    title: 'Code review',
    titleWhenCombined: 'In Review',
    // combineInvisibleStages: [Stages.InQA],
  },
  [Stages.isRejectedByQA]: {
    key: Stages.isRejectedByQA,
    title: 'Rejected by QA',
  },
  [Stages.ReadyForQA]: {
    key: Stages.ReadyForQA,
    title: 'Ready for QA',
  },
  [Stages.isBlockedInQA]: {
    key: Stages.isBlockedInQA,
    title: 'QA Awaiting Feedback',
  },
  [Stages.InQA]: {
    key: Stages.InQA,
    title: 'QA In Progress',
    titleWhenCombined: 'QA',
    // combineInvisibleStages: [Stages.isAcceptedByQA, Stages.isRejectedByQA, Stages.isBlockedInQA],
  },
  [Stages.isAcceptedByQA]: {
    key: Stages.isAcceptedByQA,
    title: 'Approved by QA',
  },
  [Stages.ReadyForMerge]: {
    key: Stages.ReadyForMerge,
    title: 'Ready for Merge',
  },
  [Stages.ReadyForRelease]: {
    key: Stages.ReadyForRelease,
    title: 'Ready for Release',
    // combineInvisibleStages: [Stages.ReadyForMerge],
  },
  [Stages.Released]: {
    key: Stages.Released,
    title: 'Released',
    titleWhenCombined: 'Released or Closed',
    // combineInvisibleStages: [Stages.Closed],
  },
  [Stages.Graveyard]: {
    key: Stages.Graveyard,
    title: Stages.Graveyard,
  },
};

export const allStages = Object.freeze(Object.keys(stageMap)) as ReadonlyArray<Stages>;
