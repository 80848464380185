import { createAsyncThunk } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';
import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';
import { getApiOptionsWithAuthHeader } from './modules/auth.selectors';
import type { AppDispatch } from './store';
import type { RootState } from './rootReducer';
import { redirectToLogin } from './modules/auth.actions';

/**
 * Creates a selector that uses deep equality checks on its input selectors.
 */
export const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

/**
 * Creates a selector that uses shallow equality checks on its input selectors, but uses deep
 * equality to memoize its result. This can help prevent unnecessary re-renders.
 */
export const createDeepEqualResultSelector: typeof createSelector = ((selector, combiner) => {
  const resultSelector = createSelector(selector, combiner);
  return createDeepEqualSelector(resultSelector, result => result);
}) as any; // No way around it.

/**
 * `atob` that works both in Node (for SSR) and in the browser.
 */
export const fromBase64: (source: string) => string =
  typeof (globalThis as any).Buffer !== 'undefined'
    ? source => Buffer.from(source, 'base64').toString('utf8')
    : source => atob(source);

export function createAuthenticatedApiThunk<T>(
  typePrefix: string,
  payloadCreator: (params: {
    dispatch: AppDispatch;
    getState: () => RootState;
    apiOptionsWithAuthHeader: RequestInit;
  }) => T | Promise<T>
) {
  return createAsyncThunk(typePrefix, async (_, { dispatch, getState }) => {
    const apiOptionsWithAuthHeader = getApiOptionsWithAuthHeader(getState() as RootState);
    if (!apiOptionsWithAuthHeader) {
      dispatch(redirectToLogin());
      return Promise.reject('Unauthenticated');
    }

    return payloadCreator({
      dispatch: dispatch as AppDispatch,
      getState: getState as () => RootState,
      apiOptionsWithAuthHeader,
    });
  });
}
